<template>
  <router-view />
</template>

<script>
import { mapState } from 'vuex'
export default {
  created() {
    this.$store.dispatch('fetchEnvData')
  },
  computed: {
    ...mapState(['envData'])
  }
}
</script>

<style lang="scss">
// for some reason this tailwind color is not getting injected sometimes (particulaly the client pipeline) not sure if this still happening
.bg-rsstatus-red {
  background-color: #ff0000;
}

// color all page backdrops yellow
html {
  // background: #e5e7eb;
  background: #5f76ad;
}

// stop overscroll (mostly for map views)
body {
  overscroll-behavior: none;
}

// fade the background behind the main nav so scrolling content dissappears
.bg-class-topfade {
  background: linear-gradient(-180deg, rgba(229, 229, 229, 1), rgba(229, 229, 229, 0));
}

/* Hide scrollbar for Chrome, Safari and Opera */
.rs-hide-scrollbars::-webkit-scrollbar,
.hide-scrollbars::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.rs-hide-scrollbars,
.hide-scrollbars {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* tags input input adjustments */
.vue-tags-input.rs-tagsinput-grow {
  background-color: transparent !important;
  border-width: 0 !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

  .ti-input {
    background-color: #fff !important;
    border-width: 0 !important;
    border-radius: 0.375rem !important;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06) !important;
    padding-top: 0.5rem !important;
    padding-right: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.5rem !important;

    .ti-new-tag-input.ti-valid {
      color: #222222 !important;
      background-color: #fff !important;
    }

    .ti-tag.ti-valid {
      background-color: #f05930 !important;
      padding-top: 0.25rem !important;
      padding-right: 0.375rem !important;
      padding-bottom: 0.25rem !important;
      padding-left: 0.375rem !important;
      border-radius: 0.15rem !important;
    }
  }
}

.vue-tags-input.rs-tagsinput-swap {
  background-color: transparent !important;
  border-width: 0 !important;
  box-shadow: none;

  .ti-input {
    background-color: transparent !important;
    border-width: 0 !important;
    border-radius: 0.375rem !important;
    box-shadow: none !important;
    padding-top: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;

    .ti-new-tag-input-wrapper {
      display: none;
    }

    .ti-tag.ti-valid {
      background-color: #3aa439 !important;
      padding-top: 12px !important;
      padding-right: 16px !important;
      padding-bottom: 12px !important;
      padding-left: 16px !important;
      border-radius: 4px !important;
    }
  }
}

.vue-tags-input.rs-tagsinput-map {
  background-color: transparent !important;
  border-width: 0 !important;
  box-shadow: none;

  .ti-input {
    background-color: #1f2937 !important;
    border-width: 0 !important;
    padding-top: 0.75rem !important;
    padding-right: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.5rem !important;

    .ti-new-tag-input.ti-valid {
      color: #fff !important;
      background-color: #1f2937 !important;
    }

    .ti-tag.ti-valid {
      background-color: #f05930 !important;
      padding-top: 0.25rem !important;
      padding-right: 0.375rem !important;
      padding-bottom: 0.25rem !important;
      padding-left: 0.375rem !important;
      border-radius: 0.15rem !important;
    }
  }
}

// Fix tailwind causes gmaps marker anchors to be white
.rs-gmappopup-tailwindborderfix .gmaps-popup-container * {
  border: none;
}

// Neumorphic Styles
.neumorph-circle-lg-yellow {
  border-radius: 1000px;
  background: #43588aff;
  box-shadow: 16px 16px 32px #293d6dff, -16px -16px 32px #5f76adff;
}

.neumorph-rect-lg-yellow {
  border-radius: 48px;
  background: #43588aff;
  box-shadow: 16px 16px 32px #293d6dff, -16px -16px 32px #5f76adff;
}

.neumorph-rect-md-yellow {
  border-radius: 24px;
  background: #43588aff;
  box-shadow: 16px 16px 32px #293d6dff, -16px -16px 32px #5f76adff;
}

.neumorph-inset-yellow {
  border-radius: 13px;
  background: #43588aff;
  box-shadow: inset 16px 16px 24px #112044ff, inset -16px -16px 24px #293d6dff;
}

// Google maps global adjustments
// Remove Google Maps popup padding
.gm-style-iw {
  padding: 0px !important;
}
// Hide close button
.gm-style .gm-style-iw button {
  display: none !important;
}

// info window container - position over anchor arrow
.gm-style .gm-style-iw-c {
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  box-shadow: none;
  top: 20px;
}

// Always hide scrollbars
.gm-style .gm-style-iw-d {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.gm-style .gm-style-iw-d::-webkit-scrollbar {
  display: none;
}
.gm-style .gm-style-iw-d .border-2 {
  border-color: tr;
}

// For overriding the map marker arrow
.gm-style .gm-style-iw-tc::after,
.gm-style .gm-style-iw-t::after {
  background: transparent;
  clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  content: '';
  height: 12px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 25px;
}

// Remove google maps branding
a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
// .gmnoprint div {
//   background: none !important;
// }
</style>
