<template>
  <div
    class="p-8 w-full h-screen overflow-scroll rs-hide-scrollbars bg-rsbrand-violet-lt"
    v-if="this.statsSetOne && this.statsPlatform"
  >
    <div class="text-white mb-6">
      <router-link class="hover:text-atbrand-purple-xxdk mr-2" to="/"> Retailer.Space</router-link>
      <pulse-icon /> Stats Dashboard
    </div>
    <div class="flex flex-wrap">
      <WidgetStatCardActiveToken
        class="rounded-3xl shadow-2xl md:mr-6 mb-6"
        v-for="(token, i) in this.statsSetOne.ActiveToken7"
        v-bind:key="i"
        v-bind:data="token"
      />
    </div>
    <div class="flex flex-wrap mb-6">
      <WidgetStatCardSingleValue
        class="neu w-1/2 md:w-auto"
        v-bind:value="Number(this.statsSetOne.ActivePlatUser7).toLocaleString()"
        v-bind:title="'Active Brokers'"
      />
      <WidgetStatCardSingleValue
        class="neu w-1/2 md:w-auto"
        v-bind:value="Number(this.statsSetOne.ActiveAnonUser7).toLocaleString()"
        v-bind:title="'Active Visitors'"
      />
    </div>
    <div class="flex flex-wrap mb-6">
      <WidgetStatCardSingleValue
        class="neu w-1/2 md:w-auto"
        v-bind:value="Number(this.statsPlatform.Cities).toLocaleString()"
        v-bind:title="'Cities'"
      />
      <WidgetStatCardSingleValue
        class="neu w-1/2 md:w-auto"
        v-bind:value="Number(this.statsPlatform.Properties).toLocaleString()"
        v-bind:title="'Properties'"
      />
      <WidgetStatCardSingleValue
        class="neu w-1/2 md:w-auto"
        v-bind:value="Number(this.statsPlatform.Spaces).toLocaleString()"
        v-bind:title="'Spaces'"
      />
      <WidgetStatCardSingleValue
        class="neu w-1/2 md:w-auto"
        v-bind:value="$FormatNumber(Number(this.statsPlatform.SqFt))"
        v-bind:title="'Feet<sup>2</sup>'"
      />
      <WidgetStatCardSingleValue
        class="neu w-1/2 md:w-auto"
        v-bind:value="$FormatNumber(Number(this.statsPlatform.SqM))"
        v-bind:title="'Meters<sup>2</sup>'"
      />
    </div>
  </div>
  <div class="p-8 w-full h-screen text-white overflow-scroll rs-hide-scrollbars bg-rsbrand-violet-lt" v-else>
    Loading...
  </div>
</template>

<script>
import WidgetStatCardActiveToken from '@/components/WidgetStatCardActiveToken.vue'
import WidgetStatCardSingleValue from '@/components/WidgetStatCardSingleValue.vue'
import { mapState } from 'vuex'
export default {
  components: {
    WidgetStatCardSingleValue,
    WidgetStatCardActiveToken
  },
  data() {
    return {
      statsUsers: false,
      statsOrgs: false,
      statsSessions: false,
      statsPlatform: false,
      statsSetOne: false
    }
  },
  computed: {
    ...mapState(['envData'])
  },
  mounted() {
    var modeAndData = {
      mode: 'all'
    }
    this.$http.post('stats-users.php', modeAndData).then((result) => {
      this.statsUsers = result.data
    })

    this.$http.post('stats-orgs.php', modeAndData).then((result) => {
      this.statsOrgs = result.data
    })

    this.$http.post('stats-sessions.php', modeAndData).then((result) => {
      this.statsSessions = result.data
    })

    this.$http.post('stats-platform.php').then((result) => {
      this.statsPlatform = result.data
    })

    this.$http.post('stats-set-one.php').then((result) => {
      this.statsSetOne = result.data
    })
  }
}
</script>

<style lang="scss" scoped>
.neu {
  border-radius: 40px;
  background: linear-gradient(145deg, #8c3757, #a64168);
  box-shadow: 20px 20px 60px #7c314e, -20px -20px 60px #ba4974;
}
</style>
