<script setup>
import { ref } from 'vue'
import CardPricingStandard from './CardPricingStandard.vue'
import CardPricingTeamS from './CardPricingTeamS.vue'
import CardPricingTeamFlex from './CardPricingTeamFlex.vue'

const gmailWhy = ref(false)

const toggleGmailWhy = () => {
  gmailWhy.value = !gmailWhy.value
}
</script>

<template>
  <div class="">
    <div class="block md:flex">
      <CardPricingStandard class="mb-4 mr-0 md:mr-4 md:w-1/3" />
      <CardPricingTeamS class="mb-4 md:w-1/3" />
      <CardPricingTeamFlex class="mb-4 ml-0 md:ml-4 md:w-1/3" />
    </div>
    <div class="max-w-2xl text-sm">
      <div class="">
        To create a Retailer.Space account you must use a gmail or google business email address. This email
        address is used only for login purposes.
        <button
          class="rounded-md bg-rsbrand-violet-lt px-1 py-0.5 text-xs font-semibold text-white text-opacity-70 shadow-md hover:bg-white hover:text-rsbrand-violet-md hover:text-opacity-100"
          @click="toggleGmailWhy"
          v-if="!gmailWhy"
        >
          Why?
        </button>
      </div>
      <div class="mt-2" v-if="gmailWhy">
        The best way for us to protect your personal information is to not ask for any in the first place.
        We're hoping that for most users, "Sign In With Google" would be preferable to creating another
        username and password. If this is not possible for you, a user name and password can be created,
        please contact us at
        <a class="text-blue-600 underline hover:text-blue-500" href="mailto:support@kirsch.agency"
          >support@kirsch.agency</a
        >
      </div>
    </div>
  </div>
</template>
