<template>
  <div class="ml-1 inline-flex text-white text-xxs font-semibold leading-none rounded-full overflow-hidden bg-black bg-opacity-50">
    <div class="px-1 py-0.5 border-r border-white border-opacity-50">Q{{ $DateToQuarterAndYear(propDate).substring(1, 2) }}</div>
    <div class="px-1 py-0.5">{{ this.propDate.substring(2, 4) }}</div>
  </div>
</template>

<script>
export default {
  props: ['propDate'],
  computed: {},
  methods: {}
}
</script>
