export default {
  install: (app, options) => {
    app.config.globalProperties.$ImageHeroURL = (imageData) => {
      if (imageData.PropImageHeroAdtlThumb) {
        return 'https://store.kirsch.agency/upload/prop-extra/' + imageData.PropImageHeroAdtlThumb
      } else if (imageData.LocImageHeroAdtlThumb) {
        return 'https://store.kirsch.agency/upload/loc-extra/' + imageData.LocImageHeroAdtlThumb
      } else if (imageData.PropImageFlrPlnAdtlThumb) {
        return 'https://store.kirsch.agency/upload/prop-extra/' + imageData.PropImageFlrPlnAdtlThumb
      } else if (imageData.LocImageFlrPlnAdtlThumb) {
        return 'https://store.kirsch.agency/upload/loc-extra/' + imageData.LocImageFlrPlnAdtlThumb
      } else {
        return 'https://store.kirsch.agency/images/image_missing_wht.svg'
      }
    }
  }
}

// app.config.globalProperties.$ImageHeroURL = (imageData) => {
//   if (imageData.PropImgHeroThumb) {
//     return 'https://store.kirsch.agency/upload/prop-main/' + imageData.PropImgHeroThumb
//   } else if (imageData.PropImageHeroAdtlThumb) {
//     return 'https://store.kirsch.agency/upload/prop-extra/' + imageData.PropImageHeroAdtlThumb
//   } else if (imageData.LocImgHeroThumb) {
//     return 'https://store.kirsch.agency/upload/loc-main/' + imageData.LocImgHeroThumb
//   } else if (imageData.LocImageHeroAdtlThumb) {
//     return 'https://store.kirsch.agency/upload/loc-extra/' + imageData.LocImageHeroAdtlThumb
//   } else if (imageData.PropImgFlrPlnThumb) {
//     return 'https://store.kirsch.agency/upload/prop-main/' + imageData.PropImgFlrPlnThumb
//   } else if (imageData.PropImageFlrPlnAdtlThumb) {
//     return 'https://store.kirsch.agency/upload/prop-extra/' + imageData.PropImageFlrPlnAdtlThumb
//   } else if (imageData.LocImgFlrPlnThumb) {
//     return 'https://store.kirsch.agency/upload//loc-main/' + imageData.LocImgFlrPlnThumb
//   } else if (imageData.LocImageFlrPlnAdtlThumb) {
//     return 'https://store.kirsch.agency/upload/loc-extra/' + imageData.LocImageFlrPlnAdtlThumb
//   } else {
//     return 'https://store.kirsch.agency/upload/unknown'
//   }
// }
