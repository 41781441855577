<template>
  <div class="bg-white h-full overflow-scroll rs-hide-scrollbars">
    <div class="m-3 flex group relative" v-for="(item, i) in this.marketReportData.items" v-bind:key="i">
      <div
        class="m-2 mr-1 w-16 h-16 bg-cover bg-no-repeat bg-center rounded-full shadow-md cursor-pointer z-30 text-white font-semibold text-3xl flex"
        v-bind:style="
          item.CostType == 'Asking'
            ? {
                'background-image':
                  'linear-gradient(rgba(0, 153, 30, 0.4), rgba(0, 153, 30, 0.4)), url(' +
                  $ImagePath(item) +
                  ')'
              }
            : {
                'background-image':
                  'linear-gradient(rgba(255, 132, 52, 0.4), rgba(255, 132, 52, 0.4)), url(' +
                  $ImagePath(item) +
                  ')'
              }
        "
        @click="this.openToken(item.PropUID)"
      >
        <div class="self-center w-full text-center">{{ item.ItemNumber }}</div>
      </div>
      <div class="flex-1 self-center mt-2 ml-2 z-20" @click="this.openToken(item.PropUID)">
        <div class="text-sm leading-tight text-black text-opacity-60 cursor-pointer mb-1">
          {{ item.ItemAddress }}
        </div>
        <div class="text-sm leading-tight text-black font-semibold cursor-pointer mb-1">
          {{ item.ItemTenant ? item.ItemTenant : 'No Tenant' }} ({{
            item.ItemSuite ? item.ItemSuite : 'No Suite'
          }})
        </div>
        <div
          class="mb-1 font-semibold cursor-pointer text-xs flex whitespace-nowrap"
          :class="item.ItemType == 'Asking' ? 'text-rsstatus-green' : 'text-rsstatus-orange'"
        >
          <div class="mr-1">{{ $FloorNumToName(item.SpacePart) }} -</div>
          <div class="mr-1">
            {{
              this.userData.DefaultSpaceQtyType == 'SqM'
                ? Number(item.SqM).toLocaleString()
                : Number(item.SqFt).toLocaleString()
            }}<span>{{ this.userData.DefaultSpaceQtyType == 'SqM' ? ' m' : ' ft' }}<sup>2</sup></span> /
          </div>
          <div class="mr-1">
            <span v-html="$CurrencyHTMLElement(item.Currency)"></span>
            {{ this.userData.DefaultSpaceQtyType == 'SqM' ? item.CostNetPrM : item.CostNetPrFt }}
          </div>
        </div>
        <div class="mb-1 text-xs font-semibold flex">
          <div :class="item.CostType == 'Asking' ? 'text-rsstatus-green' : 'text-rsstatus-orange'">
            {{ item.ItemType }}
          </div>
          <WidgetDateQuarterYearAltA v-bind:propDate="item.CostDate" />
        </div>
      </div>
      <div
        class="absolute w-full h-full z-10 rounded-md group-hover:bg-gray-100 group-hover:shadow-inner"
      ></div>
    </div>
  </div>
</template>

<script>
import WidgetDateQuarterYearAltA from '@/components/WidgetDateQuarterYearAltA.vue'
import { mapState } from 'vuex'
export default {
  components: { WidgetDateQuarterYearAltA },
  data() {
    return {}
  },
  computed: {
    ...mapState(['envData', 'userData', 'marketReportData'])
  },
  methods: {
    openToken(propUID) {
      window.open(this.envData.vueApp + '/areatoken/' + propUID, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped></style>
