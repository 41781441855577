export default {
  install: (app, options) => {
    app.config.globalProperties.$LocationIdColor = (loc) => {
      if (loc.tint == 'lt') {
        if (loc.LocID % 21 == 0) {
          return '#7CB1FF'
        } else if (loc.LocID % 21 == 1) {
          return '#93C5F9'
        } else if (loc.LocID % 21 == 2) {
          return '#ACD5F7'
        } else if (loc.LocID % 21 == 3) {
          return '#9F96CB'
        } else if (loc.LocID % 21 == 4) {
          return '#ADA1EB'
        } else if (loc.LocID % 21 == 5) {
          return '#C4C4F9'
        } else if (loc.LocID % 21 == 6) {
          return '#BAACD1'
        } else if (loc.LocID % 21 == 7) {
          return '#C6BBDA'
        } else if (loc.LocID % 21 == 8) {
          return '#D782DA'
        } else if (loc.LocID % 21 == 9) {
          return '#E69DE7'
        } else if (loc.LocID % 21 == 10) {
          return '#EAAEEA'
        } else if (loc.LocID % 21 == 11) {
          return '#EAC0CF'
        } else if (loc.LocID % 21 == 12) {
          return '#ECCED9'
        } else if (loc.LocID % 21 == 13) {
          return '#B19C86'
        } else if (loc.LocID % 21 == 14) {
          return '#C1AA90'
        } else if (loc.LocID % 21 == 15) {
          return '#CCBBA5'
        } else if (loc.LocID % 21 == 16) {
          return '#E6CBB2'
        } else if (loc.LocID % 21 == 17) {
          return '#E9D7C2'
        } else if (loc.LocID % 21 == 18) {
          return '#B0B0B0'
        } else if (loc.LocID % 21 == 19) {
          return '#BFBFBF'
        } else if (loc.LocID % 21 == 20) {
          return '#CCCCCC'
        }
      } else if (loc.tint == 'rg') {
        if (loc.LocID % 21 == 0) {
          return '#4073D9'
        } else if (loc.LocID % 21 == 1) {
          return '#379BFF'
        } else if (loc.LocID % 21 == 2) {
          return '#73BFFF'
        } else if (loc.LocID % 21 == 3) {
          return '#4E3CA7'
        } else if (loc.LocID % 21 == 4) {
          return '#6B52E5'
        } else if (loc.LocID % 21 == 5) {
          return '#9999FF'
        } else if (loc.LocID % 21 == 6) {
          return '#8368B2'
        } else if (loc.LocID % 21 == 7) {
          return '#9D85C3'
        } else if (loc.LocID % 21 == 8) {
          return '#BF15C3'
        } else if (loc.LocID % 21 == 9) {
          return '#DD49DF'
        } else if (loc.LocID % 21 == 10) {
          return '#E46DE5'
        } else if (loc.LocID % 21 == 11) {
          return '#E491AF'
        } else if (loc.LocID % 21 == 12) {
          return '#EDB2C8'
        } else if (loc.LocID % 21 == 13) {
          return '#73461D'
        } else if (loc.LocID % 21 == 14) {
          return '#926531'
        } else if (loc.LocID % 21 == 15) {
          return '#A8845A'
        } else if (loc.LocID % 21 == 16) {
          return '#DCA675'
        } else if (loc.LocID % 21 == 17) {
          return '#E5BC94'
        } else if (loc.LocID % 21 == 18) {
          return '#707070'
        } else if (loc.LocID % 21 == 19) {
          return '#8C8C8C'
        } else if (loc.LocID % 21 == 20) {
          return '#A9A9A9'
        }
      } else if (loc.tint == 'dk') {
        if (loc.LocID % 21 == 0) {
          return '#1034B9'
        } else if (loc.LocID % 21 == 1) {
          return '#0C5EFF'
        } else if (loc.LocID % 21 == 2) {
          return '#50AAFF'
        } else if (loc.LocID % 21 == 3) {
          return '#393282'
        } else if (loc.LocID % 21 == 4) {
          return '#4A3FA7'
        } else if (loc.LocID % 21 == 5) {
          return '#666AB6'
        } else if (loc.LocID % 21 == 6) {
          return '#432A7C'
        } else if (loc.LocID % 21 == 7) {
          return '#614595'
        } else if (loc.LocID % 21 == 8) {
          return '#8F0295'
        } else if (loc.LocID % 21 == 9) {
          return '#BF15C3'
        } else if (loc.LocID % 21 == 10) {
          return '#CC2FCE'
        } else if (loc.LocID % 21 == 11) {
          return '#CC5278'
        } else if (loc.LocID % 21 == 12) {
          return '#E491AF'
        } else if (loc.LocID % 21 == 13) {
          return '#341303'
        } else if (loc.LocID % 21 == 14) {
          return '#542809'
        } else if (loc.LocID % 21 == 15) {
          return '#6F4420'
        } else if (loc.LocID % 21 == 16) {
          return '#BE6C36'
        } else if (loc.LocID % 21 == 17) {
          return '#DCA675'
        } else if (loc.LocID % 21 == 18) {
          return '#313131'
        } else if (loc.LocID % 21 == 19) {
          return '#4D4D4D'
        } else if (loc.LocID % 21 == 20) {
          return '#707070'
        }
      } else {
        if (loc.LocID % 21 == 0) {
          return '#4073D9'
        } else if (loc.LocID % 21 == 1) {
          return '#379BFF'
        } else if (loc.LocID % 21 == 2) {
          return '#73BFFF'
        } else if (loc.LocID % 21 == 3) {
          return '#4E3CA7'
        } else if (loc.LocID % 21 == 4) {
          return '#6B52E5'
        } else if (loc.LocID % 21 == 5) {
          return '#9999FF'
        } else if (loc.LocID % 21 == 6) {
          return '#8368B2'
        } else if (loc.LocID % 21 == 7) {
          return '#9D85C3'
        } else if (loc.LocID % 21 == 8) {
          return '#BF15C3'
        } else if (loc.LocID % 21 == 9) {
          return '#DD49DF'
        } else if (loc.LocID % 21 == 10) {
          return '#E46DE5'
        } else if (loc.LocID % 21 == 11) {
          return '#E491AF'
        } else if (loc.LocID % 21 == 12) {
          return '#EDB2C8'
        } else if (loc.LocID % 21 == 13) {
          return '#73461D'
        } else if (loc.LocID % 21 == 14) {
          return '#926531'
        } else if (loc.LocID % 21 == 15) {
          return '#A8845A'
        } else if (loc.LocID % 21 == 16) {
          return '#DCA675'
        } else if (loc.LocID % 21 == 17) {
          return '#E5BC94'
        } else if (loc.LocID % 21 == 18) {
          return '#707070'
        } else if (loc.LocID % 21 == 19) {
          return '#8C8C8C'
        } else if (loc.LocID % 21 == 20) {
          return '#A9A9A9'
        }
      }
    }
  }
}
