export default {
  install: (app, options) => {
    app.config.globalProperties.$ImagePath = (imageInfo) => {
      var baseName
      var basePath
      if (imageInfo.PropImageHero) {
        baseName = imageInfo.PropImageHero
        basePath = 'https://store.kirsch.agency/upload/prop-extra/'
      } else if (imageInfo.LocImageHero) {
        baseName = imageInfo.LocImageHero
        basePath = 'https://store.kirsch.agency/upload/loc-extra/'
      } else if (imageInfo.PropImageFlrPln) {
        baseName = imageInfo.PropImageFlrPln
        basePath = 'https://store.kirsch.agency/upload/prop-extra/'
      } else if (imageInfo.LocImageFlrPln) {
        baseName = imageInfo.LocImageFlrPln
        basePath = 'https://store.kirsch.agency/upload/loc-extra/'
      } else {
        baseName = 'image_missing_wht.svg'
        basePath = 'https://store.kirsch.agency/images/'
      }
      var imagetype = baseName.substring(baseName.length - 4)
      var filename = baseName.slice(0, -4)
      var output
      if (imageInfo.imageType === 'full') {
        output = basePath + filename + imagetype
      } else {
        output = basePath + filename + '_thumb' + imagetype
      }
      return output
    }
  }
}

// PropID_00016571_60f77cbc6f494.jpg
// PropID_00016571_61ca48db0812b.jpg
// PropID_00016571_61ca48dc18e93.jpg
// PropID_00016571_61ca48d5e6363_flrpln.jpg
// LocID_00008057_60f77d9d3ff3b.jpg
// LocID_00008057_60f77dfba7db4.jpg
// LocID_00008057_61ca48d836504.jpg
// LocID_00008057_61ca48d8a7c43.jpg
// LocID_00008057_60f77e3a6931e_flrpln.jpg
// LocID_00008057_61ca4a1673ade_flrpln.jpg
// LocID_00008057_61ca4a16d4ee2_flrpln.png
// "LocImageHero": "LocID_00008056_615f79cb314e1.jpg",
// "LocImageFlrPln": null,
// "PropImageHero": "PropID_00016570_615f79ceca417.jpg",
// "PropImageFlrPln": null,
