<template>
  <div
    class="h-52 rounded shadow-md bg-cover bg-center relative overflow-hidden"
    v-bind:style="{
      backgroundImage: 'url(' + this.clientPortalData.cover + ')'
    }"
  >
    <router-link :to="'/client/' + clientPortalData.uid + '/tours/' + tourData.uid">
      <div class="w-full h-full bg-gray-900 bg-opacity-40 hover:bg-opacity-0 text-gray-400 hover:text-gray-50">
        <div class="px-4 absolute bottom-0 py-2.5 bg-gray-900 bg-opacity-90 w-full">
          <h1 class="text-xl">
            {{ tourData.name }}
          </h1>
          <p class="opacity-60">
            {{ tourData.description ? tourData.description : tourData.date }}
          </p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: ['tourData'],
  computed: {
    ...mapState(['clientPortalData'])
  }
}
</script>
