export default {
  install: (app, options) => {
    app.config.globalProperties.$MarkerCostColorHex = (marker) => {
      if (marker.tint == 'dk') {
        if (marker.Available === 'Yes') {
          if (marker.CostNetPrFtRaw < 50) {
            return '#007A11' // '#4CB861'
          } else if (marker.CostNetPrFtRaw < 100) {
            return '#007A11' // '#00991E'
          } else if (marker.CostNetPrFtRaw < 150) {
            return '#007A11' // '#007A11'
          } else {
            return '#007A11' // '#005C04'
          }
        } else {
          if (marker.CostNetPrFtRaw < 50) {
            return '#FF6718' // '#FFA24F'
          } else if (marker.CostNetPrFtRaw < 100) {
            return '#FF6718' // '#FF8434'
          } else if (marker.CostNetPrFtRaw < 150) {
            return '#FF6718' // '#FF6718'
          } else {
            return '#FF6718' // '#E04D00'
          }
        }
      } else if (marker.tint == 'lt') {
        if (marker.Available === 'Yes') {
          if (marker.CostNetPrFtRaw < 50) {
            return '#85D277' // '#BFE9B5'
          } else if (marker.CostNetPrFtRaw < 100) {
            return '#85D277' // '#A3E096'
          } else if (marker.CostNetPrFtRaw < 150) {
            return '#85D277' // '#85D277'
          } else {
            return '#85D277' // '#68C558'
          }
        } else {
          if (marker.CostNetPrFtRaw < 50) {
            return '#FFAF3A' // '#FFD689'
          } else if (marker.CostNetPrFtRaw < 100) {
            return '#FFAF3A' // '#FFC557'
          } else if (marker.CostNetPrFtRaw < 150) {
            return '#FFAF3A' // '#FFAF3A'
          } else {
            return '#FFAF3A' // '#FF981E'
          }
        }
      } else {
        if (marker.Available === 'Yes') {
          if (marker.CostNetPrFtRaw < 50) {
            return '#3AA439' // '#89D189'
          } else if (marker.CostNetPrFtRaw < 100) {
            return '#3AA439' // '#57BD56'
          } else if (marker.CostNetPrFtRaw < 150) {
            return '#3AA439' // '#3AA439'
          } else {
            return '#3AA439' // '#1E8C1D'
          }
        } else {
          if (marker.CostNetPrFtRaw < 50) {
            return '#FF8800' // '#FFBE84'
          } else if (marker.CostNetPrFtRaw < 100) {
            return '#FF8800' // '#FFA24F'
          } else if (marker.CostNetPrFtRaw < 150) {
            return '#FF8800' // '#FF8434'
          } else {
            return '#FF8800' // '#FF6718'
          }
        }
      }
    }
  }
}
