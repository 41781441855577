export default {
  install: (app, options) => {
    app.config.globalProperties.$MarkerCostColorClass = (marker) => {
      if (marker.Available === 'Yes') {
        if (marker.CostNetPrFtRaw < 50) {
          return 'rsbrand-green-rg' // 'rsbrand-green-xlt'
        } else if (marker.CostNetPrFtRaw < 100) {
          return 'rsbrand-green-rg' // 'rsbrand-green-lt'
        } else if (marker.CostNetPrFtRaw < 150) {
          return 'rsbrand-green-rg' // 'rsbrand-green-rg'
        } else {
          return 'rsbrand-green-rg' // 'rsbrand-green-dk'
        }
      } else {
        if (marker.CostNetPrFtRaw < 50) {
          return 'rsbrand-orange-rg' // 'rsbrand-orange-xlt'
        } else if (marker.CostNetPrFtRaw < 100) {
          return 'rsbrand-orange-rg' // 'rsbrand-orange-lt'
        } else if (marker.CostNetPrFtRaw < 150) {
          return 'rsbrand-orange-rg' // 'rsbrand-orange-rg'
        } else {
          return 'rsbrand-orange-rg' // 'rsbrand-orange-dk'
        }
      }
    }
  }
}
