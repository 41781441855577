<template>
  <div class="flex">
    <div
      class="bg-cover bg-center w-32 h-32"
      v-bind:style="{
        backgroundImage: 'url(' + this.managerData.profilePicThumb + ')'
      }"
    ></div>
    <div class="inline-block px-3 pb-1 self-center">
      <div class="text-lg font-semibold">
        {{ managerData.name }}
      </div>
      <a class="block text-gray-700 font-semibold" :href="'tel:' + managerData.phone">{{
        managerData.phone
      }}</a>
      <a
        class="block mb-0.5 text-gray-500"
        :href="'mailto:' + managerData.displayemail ? managerData.displayemail : managerData.email"
        >{{ managerData.displayemail ? managerData.displayemail : managerData.email }}</a
      >
      <div class="block text-gray-700 text-xxs" v-if="managerData.license">
        Lic: {{ managerData.license }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['managerData']
}
</script>
