export default {
  install: (app, options) => {
    app.config.globalProperties.$FormatNumber = (number) => {
      var abbrev = ['K', 'M', 'B']
      var base = Math.floor(Math.log(Math.abs(number)) / Math.log(1000))
      var suffix = abbrev[Math.min(2, base - 1)]
      base = abbrev.indexOf(suffix) + 1
      return suffix ? Math.round(number / Math.pow(1000, base), 1) + suffix : '' + number
    }
  }
}
