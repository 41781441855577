<template>
  <div class="mb-4 flex">
    <div class="neumorph-inset-yellow w-full px-4 py-3 sm:w-auto">
      <div class="max-w-sm rounded-md bg-rsbrand-violet-md px-6 pb-6 pt-4 shadow-md">
        <div class="text-xl font-bold text-white">30 Day Free Trial, then</div>
        <div class="mb-1 flex text-white">
          <div class="self-begin relative top-[10px] text-4xl font-semibold">$</div>
          <div class="mr-1.5 self-center text-8xl font-semibold tracking-tighter">12</div>
          <div class="relative -top-[6px] self-end text-lg font-semibold text-white text-opacity-50">
            / Month
          </div>
        </div>
        <div class="mb-2 flex">
          <check-bold-icon class="mr-2 flex-none text-xl text-green-600" />
          <div class="grow text-lg leading-tight text-white opacity-80">Unlimited access to all features</div>
        </div>
        <div class="mb-2 flex">
          <check-bold-icon class="mr-2 flex-none text-xl text-green-600" />
          <div class="grow text-lg leading-tight text-white opacity-80">Private data repository</div>
        </div>
        <div class="mb-2 flex">
          <check-bold-icon class="mr-2 flex-none text-xl text-green-600" />
          <div class="grow text-lg leading-tight text-white opacity-80">Cancel subscription at any time</div>
        </div>
        <div class="mb-2 flex">
          <check-bold-icon class="mr-2 flex-none text-xl text-green-600" />
          <div class="grow text-lg leading-tight text-white opacity-80">Delete your data at any time</div>
        </div>
        <button
          class="focus:shadow-outline mb-3 mt-1 rounded-md bg-rsbrand-violet-lt px-3 py-2 font-semibold text-white text-opacity-70 shadow-md hover:bg-white hover:text-rsbrand-violet-md hover:text-opacity-100"
          @click="redirectToStripe()"
          v-if="!this.isRedirecting"
        >
          Create An Account
        </button>
        <button
          class="focus:shadow-outline my-3 rounded-md bg-rsbrand-violet-lt px-3 py-2 font-semibold text-white text-opacity-70 shadow-md"
          v-if="this.isRedirecting"
        >
          Redirecting to Stripe
        </button>
        <div class="text-xs text-white">
          To create a Retailer.Space account you must use a gmail or google business email address. This email
          address is used only for login purposes.
          <button
            class="rounded-md bg-rsbrand-violet-lt px-1 py-0.5 text-xs font-semibold text-white text-opacity-70 shadow-md hover:bg-white hover:text-rsbrand-violet-md hover:text-opacity-100"
            @click="toggleGmailWhy()"
          >
            Why?
          </button>
        </div>
        <div class="mt-2 text-xs text-white" v-if="this.gmailWhy">
          The best way for us to protect your personal information is to not ask for any in the first place.
          We're hoping that for most users, "Sign In With Google" would be preferable to creating another
          username and password. If this is not possible for you, a user name and password can be created,
          please contact us at
          <a class="text-blue-300 underline hover:text-blue-500" href="mailto:support@kirsch.agency"
            >support@kirsch.agency</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isRedirecting: false,
      gmailWhy: false
    }
  },
  methods: {
    toggleGmailWhy() {
      this.gmailWhy = !this.gmailWhy
    },
    redirectToStripe() {
      this.isRedirecting = !this.isRedirecting
      this.$http.get('stripe-create-checkout-session.php').then((result) => {
        // console.log(result)
        window.location = result.data
      })
    }
  }
}
</script>
