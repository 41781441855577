<template>
  <div class="md:flex block justify-between z-50">
    <div class="flex mb-4 md:mb-6">
      <div class="h-16 flex relative">
        <router-link class="self-center" @click="isOpen = false" :to="'/'"
          ><img src="/images/rslogo_wht-blk.svg"
        /></router-link>
      </div>
      <div class="h-16 flex">
        <div class="text-5xl opacity-30 bold px-3 self-center">+</div>
      </div>
      <div class="bg-white px-3 rounded-md shadow-md h-16 max-w-xs flex">
        <img class="self-center max-h-14" :src="this.clientPortalData.logo" />
      </div>
      <div class="hidden sm:block ml-3 text-3xl font-semibold self-center opacity-70" v-if="this.$route.params.touruid">
        {{ this.clientPortalSelectedTour.name }}
      </div>
    </div>
    <div class="w-full md:w-auto flex mb-4">
      <router-link
        @click="isOpen = false"
        :to="'/client/' + clientPortalData.uid"
        class="flex-grow sm:flex-initial py-2 sm:px-3 text-center text-xl self-center rounded-md relative"
        active-class="bg-white rounded-md shadow-md"
      >
        <span><home-outline-icon class="mr-1.5" />Home</span>
      </router-link>
      <router-link
        @click="isOpen = false"
        :to="'/client/' + clientPortalData.uid + '/tours'"
        class="flex-grow sm:flex-initial py-2 sm:px-3 text-center text-xl self-center rounded-md relative"
        active-class="bg-white rounded-md shadow-md"
      >
        <span><map-legend-icon class="mr-1.5" />Tours</span>
      </router-link>
      <router-link
        @click="isOpen = false"
        :to="'/client/' + clientPortalData.uid + '/pipeline'"
        class="flex-grow sm:flex-initial py-2 sm:px-3 text-center text-xl self-center rounded-md relative"
        active-class="bg-white rounded-md shadow-md"
      >
        <span><layers-outline-icon class="mr-1.5" />Pipeline</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {},
  data() {
    return {}
  },
  created() {},
  computed: {
    ...mapState(['userData', 'clientPortalData', 'clientPortalSelectedTour'])
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
