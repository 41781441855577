<template>
  <div
    class="flex cursor-pointer flex-col overflow-hidden bg-cover bg-center"
    v-bind:style="{
      backgroundImage: 'url(https://store.kirsch.agency/upload/prop-extra/' + this.data.ImageThumb + ')'
    }"
    @click="openToken"
  >
    <div class="relative flex h-full bg-black bg-opacity-70 px-4 pb-4 pt-6 md:px-8">
      <div class="absolute right-5 top-3 text-xxs leading-tight text-white text-opacity-50">
        Active Property
      </div>
      <div
        class="flex h-16 w-16 flex-initial self-center rounded-full bg-rsbrand-violet-md text-rsbrand-violet-lt"
      >
        <div class="w-16 self-center">
          <div class="w-full text-center text-xl font-semibold leading-none text-white">
            {{ this.visits }}
          </div>
          <div class="mt-0.5 w-full text-center text-xxs leading-none text-white text-opacity-40">Visits</div>
        </div>
      </div>
      <div class="relative ml-6 flex-grow self-center">
        <div class="mb-0.5 text-sm text-white">Suite: {{ this.suite }}</div>
        <div class="text-white">{{ this.location }}</div>
        <div class="relative -left-1 mt-1.5 flex w-56 flex-wrap">
          <div
            class="mb-1.5 mr-1 flex-initial rounded-full bg-white bg-opacity-30 px-2 py-0.5 text-xs text-white text-opacity-70"
            v-for="(city, i) in this.cities"
            v-bind:key="i"
          >
            {{ city }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: ['data'],
  computed: {
    ...mapState(['envData']),
    location() {
      return this.data.Identifier.split(' - ')[0]
    },
    suite() {
      return this.data.Identifier.split(' - ')[1]
    },
    visits() {
      return this.data.Visits
    },
    cities() {
      return this.data.Cities.split(', ')
    },
    areaTokenURL() {
      return this.envData.vueApp + '/areatoken/' + this.data.SessionOwner
    }
  },
  methods: {
    openToken() {
      window.open(this.areaTokenURL, '_blank')
    }
  }
}
</script>
