<template>
  <div v-if="this.marketReportData">
    <div
      class="flex overflow-hidden shadow-md sm:rounded-md"
      v-if="this.propCountAsk > 0 && this.propCountComp > 0"
    >
      <div class="grow bg-gray-400 px-2 py-1.5 text-xs font-semibold text-white">
        <div class="mb-0.5 whitespace-nowrap">Total ({{ this.marketReportData.items.length }})</div>
        <div class="whitespace-nowrap" v-if="this.userData.DefaultSpaceQtyType == 'SqFt'">
          {{ this.spaceAvgFt.toLocaleString() }}<span class="ml-1 opacity-50">ft<sup>2</sup></span>
        </div>
        <div class="whitespace-nowrap text-xxs" v-if="this.userData.DefaultSpaceQtyType == 'SqM'">
          {{ this.spaceAvgM.toLocaleString() }}<span class="ml-1 opacity-50">m<sup>2</sup></span>
        </div>
        <div class="whitespace-nowrap text-xxs" v-if="this.userData.DefaultSpaceQtyType == 'SqFt'">
          <span
            v-for="(currency, i) in this.currencyAll"
            v-bind:key="'curr-' + i"
            v-html="$CurrencyHTMLElement(currency)"
          ></span>
          {{ Number(this.costAverageTotalFt) > 0 ? Number(this.costAverageTotalFt).toFixed(2) : '0'
          }}<span class="ml-1 opacity-50">/ft</span>
        </div>
        <div class="whitespace-nowrap text-xxs" v-if="this.userData.DefaultSpaceQtyType == 'SqM'">
          <span
            v-for="(currency, i) in this.currencyAll"
            v-bind:key="'curr-' + i"
            v-html="$CurrencyHTMLElement(currency)"
          ></span>

          {{ Number(this.costAverageTotalM) > 0 ? Number(this.costAverageTotalM).toFixed(2) : '0'
          }}<span class="ml-1 opacity-50">/m</span>
        </div>
        <div class="whitespace-nowrap text-xxs" v-if="this.userData.DefaultSpaceQtyType == 'SqFt'">
          <span
            v-for="(currency, i) in this.currencyAll"
            v-bind:key="'curr-' + i"
            v-html="$CurrencyHTMLElement(currency)"
          ></span>
          {{ Number(this.costAverageTotalFtPerMonth).toLocaleString()
          }}<span class="ml-1 opacity-50">/mo</span>
        </div>
        <div class="whitespace-nowrap text-xxs" v-if="this.userData.DefaultSpaceQtyType == 'SqM'">
          <span
            v-for="(currency, i) in this.currencyAll"
            v-bind:key="'curr-' + i"
            v-html="$CurrencyHTMLElement(currency)"
          ></span>
          {{ Number(this.costAverageTotalMPerMonth).toLocaleString()
          }}<span class="ml-1 opacity-50">/mo</span>
        </div>
      </div>
      <div class="grow bg-rsstatus-green px-2 py-1.5 text-xs font-semibold text-white">
        <div class="mb-0.5 whitespace-nowrap">Asking ({{ this.propCountAsk }})</div>
        <div class="whitespace-nowrap" v-if="this.userData.DefaultSpaceQtyType == 'SqFt'">
          {{ this.spaceAskingAvgFt.toLocaleString()
          }}<span class="ml-1 opacity-50">ft<sup>2</sup></span>
        </div>
        <div class="whitespace-nowrap text-xxs" v-if="this.userData.DefaultSpaceQtyType == 'SqM'">
          {{ this.spaceAskingAvgM.toLocaleString()
          }}<span class="ml-1 opacity-50">m<sup>2</sup></span>
        </div>
        <div class="whitespace-nowrap text-xxs" v-if="this.userData.DefaultSpaceQtyType == 'SqFt'">
          <span
            v-for="(currency, i) in this.currencyAsk"
            v-bind:key="'curr-' + i"
            v-html="$CurrencyHTMLElement(currency)"
          ></span>
          {{
            Number(this.costAverageAskingFt) > 0
              ? Number(this.costAverageAskingFt).toFixed(2)
              : '0'
          }}<span class="ml-1 opacity-50">/ft</span>
        </div>
        <div class="whitespace-nowrap text-xxs" v-if="this.userData.DefaultSpaceQtyType == 'SqM'">
          <span
            v-for="(currency, i) in this.currencyAsk"
            v-bind:key="'curr-' + i"
            v-html="$CurrencyHTMLElement(currency)"
          ></span>
          {{ Number(this.costAverageAskingM) > 0 ? Number(this.costAverageAskingM).toFixed(2) : '0'
          }}<span class="ml-1 opacity-50">/m</span>
        </div>
        <div class="whitespace-nowrap text-xxs" v-if="this.userData.DefaultSpaceQtyType == 'SqFt'">
          <span
            v-for="(currency, i) in this.currencyAsk"
            v-bind:key="'curr-' + i"
            v-html="$CurrencyHTMLElement(currency)"
          ></span>
          {{ Number(this.costAverageAskingFtPerMonth).toLocaleString()
          }}<span class="ml-1 opacity-50">/mo</span>
        </div>
        <div class="whitespace-nowrap text-xxs" v-if="this.userData.DefaultSpaceQtyType == 'SqM'">
          <span
            v-for="(currency, i) in this.currencyAsk"
            v-bind:key="'curr-' + i"
            v-html="$CurrencyHTMLElement(currency)"
          ></span>
          {{ Number(this.costAverageAskingMPerMonth).toLocaleString()
          }}<span class="ml-1 opacity-50">/mo</span>
        </div>
      </div>
      <div class="grow bg-rsstatus-orange px-2 py-1.5 text-xs font-semibold text-white">
        <div class="mb-0.5 whitespace-nowrap">Comparable ({{ this.propCountComp }})</div>
        <div class="whitespace-nowrap" v-if="this.userData.DefaultSpaceQtyType == 'SqFt'">
          {{ this.spaceCompAvgFt.toLocaleString()
          }}<span class="ml-1 opacity-50">ft<sup>2</sup></span>
        </div>
        <div class="whitespace-nowrap text-xxs" v-if="this.userData.DefaultSpaceQtyType == 'SqM'">
          {{ this.spaceCompAvgM.toLocaleString()
          }}<span class="ml-1 opacity-50">m<sup>2</sup></span>
        </div>
        <div class="whitespace-nowrap text-xxs" v-if="this.userData.DefaultSpaceQtyType == 'SqFt'">
          <span
            v-for="(currency, i) in this.currencyComp"
            v-bind:key="'curr-' + i"
            v-html="$CurrencyHTMLElement(currency)"
          ></span>
          {{ Number(this.costAverageCompFt) > 0 ? Number(this.costAverageCompFt).toFixed(2) : '0'
          }}<span class="ml-1 opacity-50">/ft</span>
        </div>
        <div class="whitespace-nowrap text-xxs" v-if="this.userData.DefaultSpaceQtyType == 'SqM'">
          <span
            v-for="(currency, i) in this.currencyComp"
            v-bind:key="'curr-' + i"
            v-html="$CurrencyHTMLElement(currency)"
          ></span>
          {{ Number(this.costAverageCompM) > 0 ? Number(this.costAverageCompM).toFixed(2) : '0'
          }}<span class="ml-1 opacity-50">/m</span>
        </div>
        <div class="whitespace-nowrap text-xxs" v-if="this.userData.DefaultSpaceQtyType == 'SqFt'">
          <span
            v-for="(currency, i) in this.currencyComp"
            v-bind:key="'curr-' + i"
            v-html="$CurrencyHTMLElement(currency)"
          ></span>
          {{ Number(this.costAverageCompFtPerMonth).toLocaleString()
          }}<span class="ml-1 opacity-50">/mo</span>
        </div>
        <div class="whitespace-nowrap text-xxs" v-if="this.userData.DefaultSpaceQtyType == 'SqM'">
          <span
            v-for="(currency, i) in this.currencyComp"
            v-bind:key="'curr-' + i"
            v-html="$CurrencyHTMLElement(currency)"
          ></span>
          {{ Number(this.costAverageCompMPerMonth).toLocaleString()
          }}<span class="ml-1 opacity-50">/mo</span>
        </div>
      </div>
    </div>
    <div
      class="rs-hide-scrollbars flex overflow-x-scroll bg-white px-2 pt-2 sm:bg-transparent sm:p-0"
      v-else
    >
      <div
        class="mr-1 flex grow flex-col rounded-md px-1.5 py-2 text-white shadow-md"
        :class="this.propCountAsk > 0 ? 'bg-rsstatus-green' : 'bg-rsstatus-orange'"
      >
        <div class="whitespace-nowrap text-center text-lg font-semibold leading-tight">
          {{ this.marketReportData.items.length }}
        </div>
        <div class="whitespace-nowrap text-center text-xxs leading-tight opacity-60">
          Properties
        </div>
      </div>
      <div
        class="mx-1 flex grow flex-col rounded-md px-1.5 py-2 text-white shadow-md"
        :class="this.propCountAsk > 0 ? 'bg-rsstatus-green' : 'bg-rsstatus-orange'"
      >
        <div class="whitespace-nowrap text-center text-lg font-semibold leading-tight">
          {{
            this.spaceAvgFt
              ? this.userData.DefaultSpaceQtyType == 'SqFt'
                ? this.spaceAvgFt.toLocaleString()
                : this.spaceAvgM.toLocaleString()
              : '0'
          }}
        </div>
        <div class="whitespace-nowrap text-center text-xxs leading-tight opacity-60">
          {{ this.userData.DefaultSpaceQtyType == 'SqFt' ? 'Average Ft' : 'Average M' }}<sup>2</sup>
        </div>
      </div>
      <div
        class="mx-1 flex grow flex-col rounded-md px-1.5 py-2 text-white shadow-md"
        :class="this.propCountAsk > 0 ? 'bg-rsstatus-green' : 'bg-rsstatus-orange'"
      >
        <div
          class="whitespace-nowrap text-center text-lg font-semibold leading-tight"
          v-if="this.userData.DefaultSpaceQtyType == 'SqFt'"
        >
          <span
            v-for="(currency, i) in this.currencyAll"
            v-bind:key="'curr-' + i"
            v-html="$CurrencyHTMLElement(currency)"
          ></span>
          {{
            Number(this.costAverageTotalFt) > 0 ? Number(this.costAverageTotalFt).toFixed(2) : '0'
          }}
        </div>
        <div
          class="whitespace-nowrap text-center text-lg font-semibold leading-tight"
          v-if="this.userData.DefaultSpaceQtyType == 'SqM'"
        >
          <span
            v-for="(currency, i) in this.currencyAll"
            v-bind:key="'curr-' + i"
            v-html="$CurrencyHTMLElement(currency)"
          ></span>

          {{ Number(this.costAverageTotalM) > 0 ? Number(this.costAverageTotalM).toFixed(2) : '0' }}
        </div>
        <div class="whitespace-nowrap text-center text-xxs leading-tight opacity-60">
          {{ this.propCountAsk > 0 ? 'Avg Asking' : 'Avg Taking' }}
          {{ this.userData.DefaultSpaceQtyType == 'SqFt' ? '/Ft' : '/M' }}
        </div>
      </div>
      <div
        class="ml-1 flex grow flex-col rounded-md px-1.5 py-2 text-white shadow-md"
        :class="this.propCountAsk > 0 ? 'bg-rsstatus-green' : 'bg-rsstatus-orange'"
      >
        <div
          class="whitespace-nowrap text-center text-lg font-semibold leading-tight"
          v-if="this.userData.DefaultSpaceQtyType == 'SqFt'"
        >
          <span
            v-for="(currency, i) in this.currencyAll"
            v-bind:key="'curr-' + i"
            v-html="$CurrencyHTMLElement(currency)"
          ></span>
          {{
            this.costAverageTotalFtPerMonth
              ? Number(this.costAverageTotalFtPerMonth).toLocaleString()
              : '0'
          }}
        </div>
        <div
          class="whitespace-nowrap text-center text-lg font-semibold leading-tight"
          v-if="this.userData.DefaultSpaceQtyType == 'SqM'"
        >
          <span
            v-for="(currency, i) in this.currencyAll"
            v-bind:key="'curr-' + i"
            v-html="$CurrencyHTMLElement(currency)"
          ></span>
          {{
            costAverageTotalMPerMonth
              ? this.costAverageTotalMPerMonth
                ? Number(this.costAverageTotalMPerMonth).toLocaleString()
                : '0'
              : '0'
          }}
        </div>
        <div class="whitespace-nowrap text-center text-xxs leading-tight opacity-60">
          {{ this.propCountAsk > 0 ? 'Avg ' : 'Avg ' }} /Month
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { math } from '@amcharts/amcharts5'
import { mapState, mapGetters } from 'vuex'
export default {
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapState(['envData', 'userData', 'marketReportData']),
    propCountAsk() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          if (item.ItemType != 'Asking') {
            return accumulator
          }
          return (accumulator += 1)
        }, 0)
      } else {
        return 0
      }
    },
    propCountComp() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          if (item.ItemType != 'Actual') {
            return accumulator
          }
          return (accumulator += 1)
        }, 0)
      } else {
        return 0
      }
    },
    spaceTotalFt() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          return (accumulator += Number(item.SqFt))
        }, 0)
      } else {
        return 0
      }
    },
    spaceAvgFt() {
      if (this.marketReportData) {
        return math.round(this.spaceTotalFt / this.marketReportData.items.length)
      } else {
        return 0
      }
    },
    spaceTotalM() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          return (accumulator += Number(item.SqM))
        }, 0)
      } else {
        return 0
      }
    },
    spaceAvgM() {
      if (this.marketReportData) {
        return math.round(this.spaceTotalM / this.marketReportData.items.length)
      } else {
        return 0
      }
    },
    spaceAskingTotalFt() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          if (item.ItemType != 'Asking') {
            return accumulator
          }
          return (accumulator += Number(item.SqFt))
        }, 0)
      } else {
        return 0
      }
    },
    spaceAskingAvgFt() {
      if (this.marketReportData) {
        return math.round(this.spaceAskingTotalFt / this.propCountAsk)
      } else {
        return 0
      }
    },
    spaceAskingTotalM() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          if (item.ItemType != 'Asking') {
            return accumulator
          }
          return (accumulator += Number(item.SqM))
        }, 0)
      } else {
        return 0
      }
    },
    spaceAskingAvgM() {
      if (this.marketReportData) {
        return math.round(this.spaceAskingTotalM / this.propCountAsk)
      } else {
        return 0
      }
    },
    spaceCompTotalFt() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          if (item.ItemType != 'Actual') {
            return accumulator
          }
          return (accumulator += Number(item.SqFt))
        }, 0)
      } else {
        return 0
      }
    },
    spaceCompAvgFt() {
      if (this.marketReportData) {
        return math.round(this.spaceCompTotalFt / this.propCountComp)
      } else {
        return 0
      }
    },
    spaceCompTotalM() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          if (item.ItemType != 'Actual') {
            return accumulator
          }
          return (accumulator += Number(item.SqM))
        }, 0)
      } else {
        return 0
      }
    },
    spaceCompAvgM() {
      if (this.marketReportData) {
        return math.round(this.spaceCompTotalM / this.propCountComp)
      } else {
        return 0
      }
    },
    costAverageTotalFt() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          return (accumulator += (Number(item.SqFt) * Number(item.CostNetPrFt)) / this.spaceTotalFt)
        }, 0)
      } else {
        return 0
      }
    },
    costAverageTotalM() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          return (accumulator += (Number(item.SqM) * Number(item.CostNetPrM)) / this.spaceTotalM)
        }, 0)
      } else {
        return 0
      }
    },
    costAverageAskingFt() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          if (item.ItemType != 'Asking') {
            return accumulator
          }
          return (accumulator +=
            (Number(item.SqFt) * Number(item.CostNetPrFt)) / this.spaceAskingTotalFt)
        }, 0)
      } else {
        return 0
      }
    },
    costAverageAskingM() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          if (item.ItemType != 'Asking') {
            return accumulator
          }
          return (accumulator +=
            (Number(item.SqM) * Number(item.CostNetPrM)) / this.spaceAskingTotalM)
        }, 0)
      } else {
        return 0
      }
    },
    costAverageCompFt() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          if (item.ItemType != 'Actual') {
            return accumulator
          }
          return (accumulator +=
            (Number(item.SqFt) * Number(item.CostNetPrFt)) / this.spaceCompTotalFt)
        }, 0)
      } else {
        return 0
      }
    },
    costAverageCompM() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          if (item.ItemType != 'Actual') {
            return accumulator
          }
          return (accumulator +=
            (Number(item.SqM) * Number(item.CostNetPrM)) / this.spaceCompTotalM)
        }, 0)
      } else {
        return 0
      }
    },
    costAverageTotalFtPerYear() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          return (accumulator += Number(item.SqFt) * Number(item.CostNetPrFt) * 12)
        }, 0)
      } else {
        return 0
      }
    },
    costAverageTotalMPerYear() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          return (accumulator += Number(item.SqM) * Number(item.CostNetPrM) * 12)
        }, 0)
      } else {
        return 0
      }
    },
    costAverageAskingFtPerYear() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          if (item.ItemType != 'Asking') {
            return accumulator
          }
          return (accumulator += Number(item.SqFt) * Number(item.CostNetPrFt) * 12)
        }, 0)
      } else {
        return 0
      }
    },
    costAverageAskingMPerYear() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          if (item.ItemType != 'Asking') {
            return accumulator
          }
          return (accumulator += Number(item.SqM) * Number(item.CostNetPrM) * 12)
        }, 0)
      } else {
        return 0
      }
    },
    costyAverageCompFtPerYear() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          if (item.ItemType != 'Actual') {
            return accumulator
          }
          return (accumulator += Number(item.SqFt) * Number(item.CostNetPrFt) * 12)
        }, 0)
      } else {
        return 0
      }
    },
    costAverageCompMPerYear() {
      if (this.marketReportData) {
        return this.marketReportData.items.reduce((accumulator, item) => {
          if (item.ItemType != 'Actual') {
            return accumulator
          }
          return (accumulator += Number(item.SqM) * Number(item.CostNetPrM) * 12)
        }, 0)
      } else {
        return 0
      }
    },
    costAverageTotalFtPerMonth() {
      if (this.marketReportData) {
        return math.round(this.costAverageTotalFt * this.spaceAvgFt)
      } else {
        return 0
      }
    },
    costAverageTotalMPerMonth() {
      if (this.marketReportData) {
        return math.round(this.costAverageTotalM * this.spaceAvgM)
      } else {
        return 0
      }
    },
    costAverageAskingFtPerMonth() {
      if (this.marketReportData) {
        return math.round(this.costAverageAskingFt * this.spaceAskingAvgFt)
      } else {
        return 0
      }
    },
    costAverageAskingMPerMonth() {
      if (this.marketReportData) {
        return math.round(this.costAverageAskingM * this.spaceAskingAvgM)
      } else {
        return 0
      }
    },
    costAverageCompFtPerMonth() {
      if (this.marketReportData) {
        return math.round(this.costAverageCompFt * this.spaceCompAvgFt)
      } else {
        return 0
      }
    },
    costAverageCompMPerMonth() {
      if (this.marketReportData) {
        return math.round(this.costAverageCompM * this.spaceCompAvgM)
      } else {
        return 0
      }
    },
    currencyAll() {
      if (this.marketReportData) {
        var currencyAll = []
        this.marketReportData.items.forEach((element) => {
          currencyAll.push(element.Currency)
        })
        return [...new Set(currencyAll)]
      } else {
        $arr = ['USD']
        return $arr
      }
    },
    currencyAsk() {
      if (this.marketReportData) {
        var currencyAsk = []
        this.marketReportData.items.forEach((element) => {
          if (element.CostType == 'Asking') {
            currencyAsk.push(element.Currency)
          }
        })
        return [...new Set(currencyAsk)]
      } else {
        $arr = ['USD']
        return $arr
      }
    },
    currencyComp() {
      if (this.marketReportData) {
        var currencyComp = []
        this.marketReportData.items.forEach((element) => {
          if (element.CostType == 'Actual') {
            currencyComp.push(element.Currency)
          }
        })
        return [...new Set(currencyComp)]
      } else {
        $arr = ['USD']
        return $arr
      }
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
