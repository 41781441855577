<template>
  <div
    class="rs-hide-scrollbars h-screen overflow-scroll bg-gray-200 px-0 pt-20 text-gray-400 sm:px-10 sm:pb-8"
    v-if="!marketReportData && !this.deactivated"
  >
    Loading...
  </div>
  <div
    class="rs-hide-scrollbars h-screen overflow-scroll bg-gray-200 px-10 pb-8 pt-10 text-gray-400"
    v-if="this.deactivated"
  >
    <div class="mb-10 font-semibold">This report is no longer available.</div>
    <router-link class="self-center" @click="isOpen = false" :to="'/'"
      ><img
        class="w-24"
        :src="
          this.marketReportData.OrgLogo
            ? 'https://store.kirsch.agency/upload/org/' + this.marketReportData.OrgLogo
            : '/images/rslogo_yel-blk.svg'
        "
    /></router-link>
  </div>
  <div
    class="rs-hide-scrollbars h-screen overflow-scroll bg-gray-200 px-0 pt-20 sm:px-10 sm:pb-8"
    v-if="marketReportData && !this.deactivated"
  >
    <div class="fixed left-0 top-0 z-20 flex h-20 w-full px-5 md:px-10">
      <div class="flex flex-wrap self-center">
        <div class="text-basis mr-2 self-end font-semibold leading-tight md:text-2xl">Market Report:</div>
        <div class="text-basis mr-2 self-end font-semibold leading-tight md:text-2xl">
          {{ this.marketReportData.Name }}
        </div>
        <div class="self-end text-xs opacity-60 md:text-sm">{{ this.userData.OrgName }}</div>
      </div>
      <div class="flex grow justify-end">
        <router-link class="self-center" @click="isOpen = false" :to="'/'"
          ><img class="h-10" src="/images/rslogo_yel-blk.svg"
        /></router-link>
      </div>
    </div>
    <div class="relative z-10 h-full md:flex" v-if="this.mobileMode">
      <div class="flex h-full w-full flex-col overflow-hidden sm:w-auto">
        <div class="flex flex-grow overflow-y-scroll" :class="this.drawerToggle ? 'z-30' : ''">
          <div class="flex flex-col" :class="this.drawerToggle ? 'w-5/6' : 'w-0'">
            <DrawerMarketReportStatistics class="flex-none" :class="this.drawerToggle ? '' : 'hidden'" />
            <DrawerMapMarketReportPublic class="grow" />
          </div>
          <div
            :class="
              this.drawerToggle
                ? 'h-full w-1/6 bg-black bg-opacity-60'
                : 'relative top-4 z-30 h-12 w-11 rounded-r-lg bg-rsbrand-violet-md shadow-lg'
            "
            @click="this.toggleDrawer"
          >
            <view-list-icon
              class="center ml-1 mt-1 text-3xl text-white opacity-70"
              v-if="!this.drawerToggle"
            />
          </div>
        </div>
      </div>
      <div class="absolute left-0 top-0 h-full w-full bg-orange-200">
        <MapMapMarketReportPublic class="z-10" />
      </div>
    </div>
    <div class="relative z-10 h-full md:flex" v-if="!this.mobileMode">
      <div
        class="h-full bg-orange-200"
        :class="this.mobileMode ? 'w-full' : 'w-2/3 overflow-hidden rounded-md shadow-md'"
      >
        <MapMapMarketReportPublic />
      </div>
      <div class="flex h-full flex-col overflow-hidden" :class="this.mobileMode ? 'w-full' : 'ml-4 w-1/3'">
        <DrawerMarketReportStatistics class="flex-none" :class="this.mobileMode ? '' : 'mb-4'" />
        <DrawerMapMarketReportPublic
          class="grow"
          :class="this.mobileMode ? '' : 'overflow-hidden rounded-md shadow-md'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DrawerMarketReportStatistics from '@/components/DrawerMarketReportStatistics.vue'
import DrawerMapMarketReportPublic from '@/components/DrawerMapMarketReportPublic.vue'
import MapMapMarketReportPublic from '@/components/MapMapMarketReportPublic.vue'
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userData', 'userAuthenticated', 'marketReportData'])
  },
  components: {
    MapMapMarketReportPublic,
    DrawerMapMarketReportPublic,
    DrawerMarketReportStatistics
  },
  data() {
    return {
      mobileMode: false,
      drawerToggle: true,
      deactivated: false
    }
  },
  mounted() {
    this.checkWidth()
    if (this.$route.params.marketreportuid) {
      this.$store.dispatch('fetchMarketReportDataPublic', this.$route.params).then((result) => {
        if (result) {
          if (!this.userAuthenticated || !this.userData) {
            this.$store.dispatch('logMarketReport', result)
          }
        } else {
          this.deactivated = true
        }
      })
    }
  },
  methods: {
    toggleDrawer() {
      this.drawerToggle = !this.drawerToggle
    },
    checkWidth() {
      if (window.innerWidth < 768) {
        this.mobileMode = true
      } else {
        this.mobileMode = false
      }
    }
  }
}
</script>
