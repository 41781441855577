<template>
  <div class="rs-hide-scrollbars h-screen overflow-scroll px-6 py-6 sm:px-8">
    <NavClient />
    <div class="w-full overflow-hidden">
      <div class="sm:flex">
        <div class="mb-3 w-full text-xl font-bold sm:w-1/4" v-if="this.clientPortalData.description">
          <div class="mb-2 text-2xl">{{ clientPortalData.name }}</div>
          <div class="text-lg font-semibold leading-snug">{{ clientPortalData.description }}</div>
        </div>
        <div
          class="mb-3 w-full text-xl font-bold sm:w-auto sm:pl-4"
          v-if="clientPortalData && clientPortalData.links.length"
        >
          <div class="mb-2 text-2xl">Links</div>
          <div class="mb-2 flex" v-for="link in clientPortalData.links" v-bind:key="link.ID">
            <div class="mr-3 rounded-md bg-white px-2 py-1 text-sm font-normal shadow-md">
              {{ link.name }}
            </div>
            <a
              class="mr-3 rounded-md bg-white bg-opacity-70 px-2 py-1 text-sm font-normal text-black shadow-md hover:bg-rsstatus-green hover:bg-opacity-100 hover:text-white"
              v-bind:href="link.link"
              target="_blank"
              ><OpenInNew
            /></a>
          </div>
        </div>
        <div
          class="mb-8 h-72 w-full text-xl font-bold sm:h-auto sm:w-auto sm:grow"
          :class="this.clientPortalData?.description || this.clientPortalData.links?.length ? 'sm:pl-4' : ''"
        >
          <div class="text-2xl" v-if="Object.keys(this.statusPairCountsOrdered).length !== 0">Pipeline</div>
          <div
            class="-ml-2 block h-full"
            :class="Object.keys(this.statusPairCountsOrdered).length !== 0 ? '' : 'hidden'"
            id="chartdiv"
            ref="chartdiv"
          ></div>
        </div>
      </div>
      <div class="my-4" v-if="this.clientPortalData.managers && this.clientPortalData.managers.length">
        <div class="mb-3 text-xl opacity-50">Tours</div>
        <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
          <CardClientPortalToursTour
            v-for="tour in this.clientPortalData.tours"
            v-bind:key="tour.id"
            v-bind:tourData="tour"
          />
        </div>
      </div>
      <div v-if="this.clientPortalData.managers && this.clientPortalData.managers.length">
        <div class="mb-3 text-xl opacity-50">Presented By</div>
        <div class="sm:flex">
          <CardClientPortalHomeBroker
            class="mb-4 mr-4 w-full overflow-hidden rounded bg-gray-200 shadow-md sm:w-auto"
            v-for="manager in clientPortalData.managers"
            v-bind:key="manager.UserID"
            v-bind:managerData="manager"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5'
import * as am5flow from '@amcharts/amcharts5/flow'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import CardClientPortalHomeBroker from '@/components/CardClientPortalHomeBroker.vue'
import NavClient from '@/components/NavClient.vue'
import CardClientPortalToursTour from '@/components/CardClientPortalToursTour.vue'
import { mapState } from 'vuex'
import OpenInNew from 'vue-material-design-icons/OpenInNew.vue'
export default {
  components: {
    NavClient,
    CardClientPortalHomeBroker,
    CardClientPortalToursTour,
    OpenInNew
  },
  computed: {
    ...mapState(['userData', 'userAuthenticated', 'clientPortalData']),
    statusByPropID() {
      if (this.clientPortalData) {
        return this.clientPortalData.events.reduce(function (acc, obj) {
          var key = obj.propid
          if (!acc[key]) {
            acc[key] = []
          }
          acc[key].push(obj.status)
          return acc
        }, {})
      }
    },
    statusFlowAsArray() {
      var tempStatusFlowArray = []
      for (var element in this.statusByPropID) {
        tempStatusFlowArray.push(this.statusByPropID[element])
      }
      return tempStatusFlowArray
    },
    statusFlowAsArrayDeDuped() {
      var tempStatusFlowAsArrayDeDuped = []
      for (var element in this.statusFlowAsArray) {
        tempStatusFlowAsArrayDeDuped.push(
          this.statusFlowAsArray[element].filter(function (value, index, arr) {
            return value != arr[index + 1]
          })
        )
      }
      return tempStatusFlowAsArrayDeDuped
    },
    statusFlowArrayNormalized() {
      var tempStatusFlowArrayNormalized = []
      for (var element in this.statusFlowAsArrayDeDuped) {
        var tempNormalStatusFlow = []
        if (this.statusFlowAsArrayDeDuped[element].length == 1) {
          if (this.statusFlowAsArrayDeDuped[element][0] !== 'Submission') {
            tempNormalStatusFlow = ['Submission', this.statusFlowAsArrayDeDuped[element][0]]
            tempStatusFlowArrayNormalized.push(tempNormalStatusFlow)
          } else {
            tempNormalStatusFlow = [this.statusFlowAsArrayDeDuped[element][0], 'Consideration']
            tempStatusFlowArrayNormalized.push(tempNormalStatusFlow)
          }
        } else {
          if (this.statusFlowAsArrayDeDuped[element][0] !== 'Submission') {
            this.statusFlowAsArrayDeDuped[element].unshift('Submission')
            tempStatusFlowArrayNormalized.push(this.statusFlowAsArrayDeDuped[element])
          } else {
            tempStatusFlowArrayNormalized.push(this.statusFlowAsArrayDeDuped[element])
          }
        }
      }
      return tempStatusFlowArrayNormalized
    },
    statusFlowArrayNormalizedUnique() {
      var flowArrayNormalizedUnique = []
      this.statusFlowArrayNormalized.forEach((element) => {
        var unique = element.filter((temparray, index) => {
          return element.indexOf(temparray) === index
        })
        flowArrayNormalizedUnique.push(unique)
      })
      return flowArrayNormalizedUnique
    },
    statusFlowArrayCleanedAndSorted() {
      var sortByOrder = [
        'Submission',
        'Consideration',
        'To Tour',
        'Working',
        'Hold',
        'Rejected',
        'Failed',
        'In Lease',
        'Existing Location',
        'Disposition'
      ]
      var flowArraySorted = []
      this.statusFlowArrayNormalizedUnique.forEach((element) => {
        var dataToSort = element
        var sortedData = dataToSort.sort((a, b) => sortByOrder.indexOf(a) - sortByOrder.indexOf(b))
        flowArraySorted.push(sortedData)
      })
      return flowArraySorted
    },
    flowArrayPairs() {
      var tempFlowArrayPairs = []
      for (var element in this.statusFlowArrayCleanedAndSorted) {
        if (this.statusFlowArrayCleanedAndSorted[element].length === 2) {
          tempFlowArrayPairs.push(this.statusFlowArrayCleanedAndSorted[element])
        } else if (this.statusFlowArrayCleanedAndSorted[element].length === 3) {
          var tempArray = this.statusFlowArrayCleanedAndSorted[element].reduce((acc, v, i, a) => {
            if (i < a.length - 1) {
              acc.push([a[i], a[i + 1]])
            }
            return acc
          }, [])
          tempArray.forEach((element) => {
            tempFlowArrayPairs.push(element)
          })
        } else {
          tempFlowArrayPairs.push([
            this.statusFlowArrayCleanedAndSorted[element][0],
            this.statusFlowArrayCleanedAndSorted[element][
              this.statusFlowArrayCleanedAndSorted[element].length - 1
            ]
          ])
        }
      }
      return tempFlowArrayPairs
    },
    statusPairCounts() {
      return this.flowArrayPairs.reduce(function (result, current) {
        if (!result[current]) {
          result[current] = 1
        } else {
          result[current] += 1
        }
        return result
      }, {})
    },
    statusPairCountsOrdered() {
      let statusOrderedByValue = Object.entries(this.statusPairCounts).sort((prev, next) => next[1] - prev[1])
      var statusArraySubmission = statusOrderedByValue.filter((el) => {
        return el[0].toLowerCase().indexOf('sub') === 0
      })
      var statusArrayConsideration = statusOrderedByValue.filter((el) => {
        return el[0].toLowerCase().indexOf('cons') === 0
      })
      var statusArrayToTour = statusOrderedByValue.filter((el) => {
        return el[0].toLowerCase().indexOf('to') === 0
      })
      var statusArrayWorking = statusOrderedByValue.filter((el) => {
        return el[0].toLowerCase().indexOf('work') === 0
      })
      var statusArrayHold = statusOrderedByValue.filter((el) => {
        return el[0].toLowerCase().indexOf('hold') === 0
      })
      var statusArrayRejected = statusOrderedByValue.filter((el) => {
        return el[0].toLowerCase().indexOf('reject') === 0
      })
      var statusArrayFailed = statusOrderedByValue.filter((el) => {
        return el[0].toLowerCase().indexOf('fail') === 0
      })
      var statusArrayInLease = statusOrderedByValue.filter((el) => {
        return el[0].toLowerCase().indexOf('in') === 0
      })
      var statusArrayExistingLocation = statusOrderedByValue.filter((el) => {
        return el[0].toLowerCase().indexOf('exist') === 0
      })
      var statusArrayDisposition = statusOrderedByValue.filter((el) => {
        return el[0].toLowerCase().indexOf('disp') === 0
      })
      var orderedObj = [
        ...statusArraySubmission,
        ...statusArrayConsideration,
        ...statusArrayToTour,
        ...statusArrayWorking,
        ...statusArrayHold,
        ...statusArrayRejected,
        ...statusArrayFailed,
        ...statusArrayInLease,
        ...statusArrayExistingLocation,
        ...statusArrayDisposition
      ]
      var orderedObjClean = {}
      orderedObj.forEach((el) => {
        orderedObjClean[el[0]] = el[1]
      })
      return orderedObjClean
    },
    buyerEventsChartData() {
      var tempFinalStringArray = []
      var idHolder = 1
      for (var element in this.statusPairCountsOrdered) {
        var tempMidArray = element.split(',')
        var tempFinalString = {
          from: tempMidArray[0],
          to: tempMidArray[1],
          value: this.statusPairCountsOrdered[element],
          id: idHolder
        }
        tempFinalStringArray.push(tempFinalString)
        idHolder++
      }
      return tempFinalStringArray
      // var testArray = [
      //   { from: 'Submission', to: 'Existing Location', value: 107, id: 1 },
      //   { from: 'Submission', to: 'Rejected', value: 88, id: 2 },
      //   { from: 'Submission', to: 'To Tour', value: 54, id: 3 },
      //   { from: 'Submission', to: 'Consideration', value: 12, id: 4 },
      //   { from: 'Submission', to: 'Hold', value: 11, id: 5 },
      //   { from: 'Submission', to: 'Working', value: 8, id: 6 },
      //   { from: 'Submission', to: 'Failed', value: 7, id: 7 },
      //   { from: 'Submission', to: 'Disposition', value: 4, id: 8 },
      //   { from: 'Submission', to: 'In Lease', value: 2, id: 9 },
      //   { from: 'To Tour', to: 'Rejected', value: 17, id: 10 },
      //   { from: 'To Tour', to: 'Failed', value: 4, id: 11 },
      //   { from: 'To Tour', to: 'Working', value: 1, id: 12 },
      //   { from: 'Working', to: 'Rejected', value: 2, id: 13 },
      //   { from: 'Working', to: 'Failed', value: 2, id: 14 },
      //   { from: 'Working', to: 'Existing Location', value: 1, id: 15 },
      //   { from: 'Working', to: 'Hold', value: 1, id: 16 },
      //   { from: 'Hold', to: 'Rejected', value: 7, id: 17 },
      //   { from: 'Existing Location', to: 'Disposition', value: 19, id: 19 },
      //   { from: 'Existing Location', to: 'Hold', value: 1, id: 21 }
      // ]
      // { from: 'Rejected', to: 'Working', value: 1, id: 18 },
      // { from: 'Existing Location', to: 'Working', value: 1, id: 20 },
      // return testArray
    }
  },
  mounted() {
    if (
      this.$route.params.clientuid &&
      (!this.clientPortalData || this.$route.params.clientuid != this.clientPortalData.uid)
    ) {
      this.$store.dispatch('fetchClientPortalData', this.$route.params).then((result) => {
        if (!this.userAuthenticated || !this.userData) {
          this.$store.dispatch('logClientPortal', result)
        }
      })
    }
    this.loadAmChartSankey()
  },
  updated() {
    this.loadAmChartSankey()
  },
  beforeDestroy() {
    if (this.root) {
      this.root.dispose()
    }
  },
  methods: {
    loadAmChartSankey() {
      if (this.root) {
        this.root.dispose()
      }
      let root = am5.Root.new(this.$refs.chartdiv)
      root.setThemes([am5themes_Animated.new(root)])
      var series = root.container.children.push(
        am5flow.Sankey.new(root, {
          sourceIdField: 'from',
          targetIdField: 'to',
          valueField: 'value',
          nodeWidth: 90,
          nodePadding: 4,
          nodeAlign: 'justify'
        })
      )
      series.nodes.rectangles.template.setAll({
        fillOpacity: 1,
        cornerRadiusTL: 4,
        cornerRadiusTR: 4,
        cornerRadiusBL: 4,
        cornerRadiusBR: 4
      })
      series.nodes.labels.template.setAll({
        fontSize: 11,
        fill: '#fff'
      })
      series.data.setAll(this.buyerEventsChartData)
      this.root = root
    }
  }
}
</script>
