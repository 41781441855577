<template>
  <div class="px-8 py-6 flex flex-col">
    <div class="flex-initial self-center text-2xl text-white font-semibold">
      {{ this.value }}
    </div>
    <div
      class="flex-initial self-center text-center leading-tight text-sm text-white text-opacity-50"
      v-html="this.title"
    ></div>
  </div>
</template>

<script>
export default {
  props: ['value', 'title', 'mode'],
  computed: {},
  methods: {}
}
</script>
