<template>
  <div class="h-full bg-gray-50">
    <GMapMap
      class="overflow-hidden rounded-t-md"
      style="height: 100%"
      :options="this.mapOptionsClean"
      :center="this.mapCenter"
      ref="siteInputMap"
    >
      <GMapInfoWindow
        class="rs-hide-scrollbars m-2 flex h-14 w-14 cursor-pointer rounded-full border-2 border-white shadow-md"
        :class="prop.CostType == 'Asking' ? 'bg-rsstatus-green' : 'bg-rsstatus-orange'"
        v-for="(prop, i) in marketReportData.items"
        :key="i"
        :position="{ lat: Number(prop.Lat), lng: Number(prop.Lng) }"
        @click="this.openToken(prop.PropUID)"
      >
        <div
          class="flex w-full flex-col self-center overflow-hidden rounded-md text-center text-xl font-bold text-white"
        >
          {{ prop.ItemNumber }}
        </div>
      </GMapInfoWindow>
    </GMapMap>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {},
  data: function () {
    return {
      gmapMap: false,
      debounceTimeout: null,
      mapCenter: { lat: 40.689247, lng: -74.044502 }
    }
  },
  created() {},
  mounted() {
    if (!this.gmapMap) {
      this.$refs.siteInputMap.$mapPromise.then((mapObject) => {
        this.gmapMap = mapObject
        this.gmapMap.fitBounds(this.reportBounds)
      })
    }
  },
  computed: {
    ...mapState(['envData', 'userData', 'mapOptionsClean', 'marketReportData']),
    reportBounds() {
      var finalBounds
      if (this.marketReportData.items.length > 0) {
        var latSouth
        var latNorth
        var lngWest
        var lngEast
        this.marketReportData.items.forEach((prop) => {
          if (latSouth == null || latNorth == null || lngWest == null || lngEast == null) {
            latSouth = latNorth = prop.Lat
            lngWest = lngEast = prop.Lng
          } else {
            if (parseFloat(prop.Lat) > parseFloat(latNorth)) latNorth = parseFloat(prop.Lat)
            if (parseFloat(prop.Lat) < parseFloat(latSouth)) latSouth = parseFloat(prop.Lat)
            if (parseFloat(prop.Lng) > parseFloat(lngEast)) lngEast = parseFloat(prop.Lng)
            if (parseFloat(prop.Lng) < parseFloat(lngWest)) lngWest = parseFloat(prop.Lng)
          }
        })
        finalBounds = {
          north: Number(latNorth),
          east: Number(lngEast),
          south: Number(latSouth),
          west: Number(lngWest)
        }
      } else {
        finalBounds = {
          north: 44.8809,
          east: -80.6403,
          south: 40.8807,
          west: -90.6405
        }
      }
      return finalBounds
    }
  },
  methods: {
    openToken(propUID) {
      window.open(this.envData.vueApp + '/areatoken/' + propUID, '_blank')
    }
  }
}
</script>

<style lang="scss">
// Problems with the map container having no height in the vue3-gmap generated html
.vue-map-container {
  height: calc(100%);
}
</style>
