export default {
  install: (app, options) => {
    app.config.globalProperties.$FloorNumToNameShort = (floor) => {
      if (floor == '-3') {
        return 'Util'
      } else if (floor == '-2') {
        return 'Bsmnt'
      } else if (floor == '-1') {
        return 'Bsmnt'
      } else if (floor == '1') {
        return 'Main'
      } else if (floor == '2') {
        return 'Second'
      } else if (floor == '3') {
        return 'Third'
      } else if (floor == '4') {
        return 'Additl'
      } else {
        return 'Main'
      }
    }
  }
}
