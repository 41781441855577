export default {
  install: (app, options) => {
    app.config.globalProperties.$CurrencyHTMLElement = (currency) => {
      if (currency == 'USD') {
        return '&dollar;'
      } else if (currency == 'EUR') {
        return '&euro;'
      } else if (currency == 'JPY') {
        return '&yen;'
      } else if (currency == 'GBP') {
        return '&pound;'
      } else if (currency == 'MXN') {
        return '&#8369;'
      } else if (currency == 'RUB') {
        return '&#8381;'
      } else if (currency == 'CNY') {
        return '&#20803;'
      } else if (currency == 'INR') {
        return '&#8377;'
      } else if (currency == 'BTC') {
        return '&#8383;'
      } else {
        return '&dollar;'
      }
    }
  }
}
