import { createStore } from 'vuex'
import axios from 'axios'
import LogRocket from 'logrocket'
import createPlugin from 'logrocket-vuex'

// init log rocket if env var is 'on'
if (process.env.VUE_APP_LOG_ROCKET == 'on') {
  LogRocket.init('h57mwn/retailerspace')
}

//create log rocket plugin
const logrocketPlugin = createPlugin(LogRocket)

export default createStore({
  plugins: [logrocketPlugin],
  state: {
    envData: false,
    userData: false,
    userAuthenticated: false,
    orgInfo: false,
    orgUsers: false,
    orgClients: false,
    orgMarkets: false,
    orgAttributes: false,
    orgCategories: false,
    orgMarketReports: false,
    statCardsUser: false,
    clientContextData: false,
    clientContextPipelineOnly: false,
    clientPortalData: false,
    clientPortalSelectedTour: false,
    marketReportData: false,
    marketReportOnly: false,
    clientPipelineFilter: {
      filterString: '',
      status: {
        Submission: true,
        'To Tour': true,
        Hold: true,
        Working: true,
        'In Lease': true,
        Rejected: false,
        Failed: false,
        'Existing Location': false,
        Disposition: false
      }
    },
    statsUser: false,
    statsTeam: false,
    statsUsers: false,
    statsUserSessions: false,
    statsUserSessionsByOrg: false,
    statsOrg: false,
    statsOrgs: false,
    statsPlatform: false,
    statsSessions: false,
    clientInfo: false,
    clientUsers: false,
    clientPolygons: false,
    clientLinks: false,
    clientTours: false,
    clientProps: false,
    clientSelectedTour: false,
    clientSelectedTourPages: false,
    propFilterParams: {
      MapNELat: 41.8809,
      MapNELng: -87.6403,
      MapSWLat: 41.8807,
      MapSWLng: -87.6405,
      PropSpaceRangeMin: '',
      PropSpaceRangeMax: '',
      PropCostRangeMin: '',
      PropCostRangeMax: '',
      Availability: '',
      filterMapAreaOnly: true,
      costDateAgeMonths: 240,
      includePropsWithNoSpace: true,
      includePropsWithNoCost: true,
      limitFilterResultsTo: 250,
      PropIDs: [],
      Filters: [],
      FiltersGeo: []
    },
    propFilterResultsProps: false,
    mapOptions: {
      center: {
        lat: 41.8808,
        lng: -87.6404
      },
      zoom: 20,
      streetViewControl: true,
      fullscreenControl: true,
      mapTypeControl: true,
      styles: [
        {
          featureType: 'administrative.neighborhood',
          elementType: 'geometry',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'geometry.fill',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.icon',
          stylers: [{ color: '#4a4a4a' }, { saturation: -100 }, { lightness: 65 }]
        },
        {
          featureType: 'poi.medical',
          elementType: 'geometry',
          stylers: [{ color: '#e0a3a3' }, { lightness: 50 }]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{ color: '#d4f7d6' }]
        },
        {
          featureType: 'poi.place_of_worship',
          elementType: 'geometry',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'poi.school',
          elementType: 'geometry',
          stylers: [{ color: '#f1f5bc' }, { visibility: 'on' }]
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [{ lightness: 80 }]
        },
        {
          featureType: 'road.local',
          elementType: 'geometry.fill',
          stylers: [{ color: '#c4c4c4' }, { visibility: 'simplified' }]
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [{ visibility: 'on' }]
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.stroke',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'transit',
          elementType: 'labels.text.fill',
          stylers: [{ visibility: 'off' }]
        }
      ]
    },
    mapOptionsClean: {
      center: {
        lat: 41.8808,
        lng: -87.6404
      },
      zoom: 20,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      styles: [
        {
          featureType: 'administrative.neighborhood',
          elementType: 'geometry',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'geometry.fill',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.icon',
          stylers: [{ color: '#4a4a4a' }, { saturation: -100 }, { lightness: 65 }]
        },
        {
          featureType: 'poi.medical',
          elementType: 'geometry',
          stylers: [{ color: '#e0a3a3' }, { lightness: 50 }]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{ color: '#d4f7d6' }]
        },
        {
          featureType: 'poi.place_of_worship',
          elementType: 'geometry',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'poi.school',
          elementType: 'geometry',
          stylers: [{ color: '#f1f5bc' }, { visibility: 'on' }]
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [{ lightness: 80 }]
        },
        {
          featureType: 'road.local',
          elementType: 'geometry.fill',
          stylers: [{ color: '#c4c4c4' }, { visibility: 'simplified' }]
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [{ visibility: 'on' }]
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.stroke',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'transit',
          elementType: 'labels.text.fill',
          stylers: [{ visibility: 'off' }]
        }
      ]
    },
    locToolProps: false,
    tokenSecure: false,
    tokenSecureActive: false,
    tokenClient: false,
    tokenClientActive: false,
    tokenPublic: false,
    tokenPublicActive: false,
    formModal: false,
    formModalActive: false,
    printViewImages: {
      'img-slot-1': false,
      'img-slot-2': false,
      'img-slot-3': false,
      'img-slot-4': false,
      'img-slot-5': false,
      'img-slot-6': false
    },
    imageChange: 123456789
  },
  getters: {
    GetPropFilterParamsFriendly(state) {
      var GetPropFilterParamsFriendly = {
        OrgUID: state.userData.OrgUID,
        MapNELat: Number(state.propFilterParams.MapNELat),
        MapNELng: Number(state.propFilterParams.MapNELng),
        MapSWLat: Number(state.propFilterParams.MapSWLat),
        MapSWLng: Number(state.propFilterParams.MapSWLng),
        PropSpaceRangeMin: state.propFilterParams.PropSpaceRangeMin,
        PropSpaceRangeMax: state.propFilterParams.PropSpaceRangeMax,
        PropCostRangeMin: state.propFilterParams.PropCostRangeMin,
        PropCostRangeMax: state.propFilterParams.PropCostRangeMax,
        Availability: state.propFilterParams.Availability,
        filterMapAreaOnly: state.propFilterParams.filterMapAreaOnly,
        costDateAgeMonths: state.propFilterParams.costDateAgeMonths,
        includePropsWithNoSpace: state.propFilterParams.includePropsWithNoSpace,
        includePropsWithNoCost: state.propFilterParams.includePropsWithNoCost,
        limitFilterResultsTo: state.propFilterParams.limitFilterResultsTo,
        Attribute: state.propFilterParams.Filters.reduce(
          (accumulator, item) => (item.Type == 'Attribute' && accumulator.push(item.Value), accumulator),
          []
        ),
        ClientIDs: state.propFilterParams.Filters.reduce(
          (accumulator, item) => (item.Type == 'Client' && accumulator.push(item.Value), accumulator),
          []
        ),
        Probability: state.propFilterParams.Filters.reduce(
          (accumulator, item) => (item.Type == 'Probability' && accumulator.push(item.Value), accumulator),
          []
        ),
        Status: state.propFilterParams.Filters.reduce(
          (accumulator, item) => (item.Type == 'Status' && accumulator.push(item.Value), accumulator),
          []
        ),
        Market: state.propFilterParams.FiltersGeo.reduce(
          (accumulator, item) => (item.Type == 'Market' && accumulator.push(item.Value), accumulator),
          []
        ),
        City: state.propFilterParams.FiltersGeo.reduce(
          (accumulator, item) => (item.Type == 'City' && accumulator.push(item.Value), accumulator),
          []
        ),
        State: state.propFilterParams.FiltersGeo.reduce(
          (accumulator, item) => (item.Type == 'State' && accumulator.push(item.Value), accumulator),
          []
        ),
        Tenant: state.propFilterParams.Filters.reduce(
          (accumulator, item) => (item.Type == 'Tenant' && accumulator.push(item.Value), accumulator),
          []
        ),
        Category: state.propFilterParams.Filters.reduce(
          (accumulator, item) => (item.Type == 'Category' && accumulator.push(item.Value), accumulator),
          []
        ),
        LocIDs: state.propFilterParams.Filters.reduce(
          (accumulator, item) => (item.Type == 'Location' && accumulator.push(item.Value), accumulator),
          []
        ),
        PropIDs: state.propFilterParams.PropIDs
      }
      return GetPropFilterParamsFriendly
    },
    getPropFilterResultsBounds(state) {
      var finalBounds
      if (state.propFilterResultsProps.length > 0) {
        var east
        var west
        var north
        var south
        state.propFilterResultsProps.forEach((prop) => {
          if (east == null || west == null || north == null || south == null) {
            east = west = parseFloat(prop.PropLng)
            north = south = parseFloat(prop.PropLat)
          } else {
            if (parseFloat(prop.PropLng) > east) {
              east = parseFloat(prop.PropLng)
            }
            if (parseFloat(prop.PropLng) < west) {
              west = parseFloat(prop.PropLng)
            }
            if (parseFloat(prop.PropLat) > north) {
              north = parseFloat(prop.PropLat)
            }
            if (parseFloat(prop.PropLat) < south) {
              south = parseFloat(prop.PropLat)
            }
          }
        })
        finalBounds = {
          east: parseFloat(east),
          west: parseFloat(west),
          north: parseFloat(north),
          south: parseFloat(south)
        }
      } else {
        finalBounds = {
          east: -80.64,
          west: -81.64,
          north: 41.88,
          south: 40.88
        }
      }
      return finalBounds
    },
    getClientPortalPropsFiltered(state) {
      var statusArray = Object.keys(state.clientPipelineFilter.status).filter(
        (k) => state.clientPipelineFilter.status[k] === true
      )
      var filteredMarkers = []
      if (state.clientPipelineFilter.filterString != '') {
        filteredMarkers = state.clientPortalData.props.filter((item) => {
          return (
            item.LocName.toLowerCase().indexOf(state.clientPipelineFilter.filterString.toLowerCase()) !== -1
          )
        })
      } else {
        filteredMarkers = state.clientPortalData.props.filter((marker) => statusArray.includes(marker.Status))
      }
      return filteredMarkers
    },
    getClientToolsPropsFiltered(state) {
      // console.log('state.clientProps - ', state.clientProps)
      var statusArray = Object.keys(state.clientPipelineFilter.status).filter(
        (k) => state.clientPipelineFilter.status[k] === true
      )
      var filteredMarkers = []
      if (state.clientProps) {
        if (state.clientPipelineFilter.filterString != '') {
          filteredMarkers = state.clientProps.filter((item) => {
            return (
              item.LocName.toLowerCase().indexOf(state.clientPipelineFilter.filterString.toLowerCase()) !== -1
            )
          })
        } else {
          filteredMarkers = state.clientProps.filter((marker) => statusArray.includes(marker.Status))
        }
      }
      return filteredMarkers
    }
  },
  actions: {
    fetchEnvData({ commit }) {
      axios.post('php-env.php').then((result) => {
        if (result.data.phpAPI == 'http://rspace-vue/api/') {
          result.data.appLocationName = 'Retailer.Space Localhost'
          result.data.vueApp = 'http://localhost:3000'
        } else if (result.data.phpAPI == 'https://app.kirsch.work/api/') {
          result.data.appLocationName = 'Retailer.Space KW'
        } else if (result.data.phpAPI == 'https://dev.retailer.space/api/') {
          result.data.appLocationName = 'Retailer.Space Development'
        } else if (result.data.phpAPI == 'https://retailer.space/api/') {
          result.data.appLocationName = 'Retailer.Space'
        } else {
          result.data.appLocationName = 'Unable to set'
        }
        commit('setEnvData', result.data)
      })
    },
    fetchUserData({ commit }, auth0userdata) {
      return axios.post('php-login.php', auth0userdata).then((result) => {
        if (result.data) {
          ///////////////////////////////////////
          LogRocket.identify(result.data.Email, {
            name: result.data.FirstName + ' ' + result.data.LastName,
            email: result.data.Email,
            // user variables
            orgName: result.data.OrgName
          })
          ///////////////////////////////////////
          commit('setUserData', result.data)
          return result
        } else {
          return false
        }
      })
    },
    logClientPortal({ commit }, clientdata) {
      return axios.post('php-logclientportal.php', clientdata).then((result) => {
        ///////////////////////////////////////
        LogRocket.identify(result.data.FirstName, {
          name: result.data.FirstName,
          email: 'anon_client@unknown.com',
          // user variables
          clientUID: result.data.UID
        })
        ///////////////////////////////////////
        commit('setUnknownUserData', result.data)
      })
    },
    logMarketReport({ commit }, marketreportdata) {
      return axios.post('php-logmarketreport.php', marketreportdata).then((result) => {
        ///////////////////////////////////////
        LogRocket.identify(result.data.FirstName, {
          name: result.data.FirstName,
          email: 'anon_report@unknown.com',
          // user variables
          reportUID: result.data.UID
        })
        ///////////////////////////////////////
        commit('setUnknownUserData', result.data)
      })
    },
    logTokenPublic({ commit }, tokendata) {
      return axios.post('php-logtokenpublic.php', tokendata).then((result) => {
        ///////////////////////////////////////
        LogRocket.identify(tokendata.AddressStreet, {
          name: tokendata.AddressStreet + ' - ' + (tokendata.PropSuite ? tokendata.PropSuite : 'No Suite'),
          email: 'anon_token@unknown.com',
          // user variables
          propUID: tokendata.PropUID
        })
        ///////////////////////////////////////
        commit('setUnknownUserData', result.data)
      })
    },
    fetchOrgInfo({ commit }, userdata) {
      var callData = {
        method: 'read',
        uid: userdata.OrgUID
      }
      return axios.post('org-crud.php', callData).then((result) => {
        commit('setOrgInfo', result.data)
      })
    },
    fetchUsersByOrg({ commit }, userdata) {
      return axios.post('users-byorg.php', userdata).then((result) => {
        commit('setUsersByOrg', result.data)
      })
    },
    fetchStatCards({ commit }) {
      return axios.post('stats-card-list.php', this.state.userData).then((result) => {
        commit('setStatCardsByUser', result.data)
        return result.data
      })
    },
    fetchClientsByOrg({ commit }, userdata) {
      return axios.post('clients-byorg.php', userdata).then((result) => {
        commit('setClientsByOrg', result.data)
      })
    },
    fetchClientInfo({ commit }, clientdata) {
      return axios.post('client-info.php', clientdata).then((result) => {
        commit('setClientInfo', result.data)
      })
    },
    fetchClientUsers({ commit }, clientdata) {
      return axios.post('client-users.php', clientdata).then((result) => {
        commit('setClientUsers', result.data)
      })
    },
    fetchClientPolygons({ commit }, clientdata) {
      return axios.post('client-polygons.php', clientdata).then((result) => {
        commit('setClientPolygons', result.data)
      })
    },
    fetchClientLinks({ commit }, clientdata) {
      return axios.post('client-links.php', clientdata).then((result) => {
        commit('setClientLinks', result.data)
      })
    },
    fetchClientTours({ commit }, clientdata) {
      return axios.post('client-tours.php', clientdata).then((result) => {
        commit('setClientTours', result.data)
      })
    },
    fetchClientProps({ commit }, clientdata) {
      return axios.post('client-props.php', clientdata).then((result) => {
        commit('setClientProps', result.data)
      })
    },
    fetchClientSelectedTour({ commit }, tourdata) {
      return axios.post('client-selectedtourpages.php', tourdata).then((result) => {
        commit('setClientSelectedTour', tourdata)
        commit('setClientSelectedTourPages', result.data)
      })
    },
    resetClientSelectedTour({ commit }) {
      commit('resetClientSelectedTour')
      commit('resetClientSelectedTourPages')
    },
    fetchMarketsByOrg({ commit }, userdata) {
      return axios.post('markets-byorg.php', userdata).then((result) => {
        commit('setMarketsByOrg', result.data)
      })
    },
    fetchAttributesByOrg({ commit }, userdata) {
      return axios.post('attributes-byorg.php', userdata).then((result) => {
        commit('setAttributesByOrg', result.data)
      })
    },
    fetchCategoriesByOrg({ commit }, userdata) {
      return axios.post('categories-byorg.php', userdata).then((result) => {
        commit('setCategoriesByOrg', result.data)
      })
    },
    fetchMarketReportsByOrg({ commit }, userdata) {
      return axios.post('marketreports-byorg.php', userdata).then((result) => {
        commit('setMarketReportsByOrg', result.data)
      })
    },
    fetchClientContextlData({ commit }, clientuid) {
      return axios.post('clientcontext-clientdata.php', { clientuid: clientuid }).then((result) => {
        commit('setClientContextData', result.data)
        commit('clientContextPipelineOnlyResetSet')
        return result.data
      })
    },
    resetClientContextData({ commit }) {
      console.log('resetClientContextData')
      commit('resetClientContextData')
    },
    fetchClientPortalData({ commit }, clientdata) {
      return axios.post('clientportal-clientdata.php', clientdata).then((result) => {
        commit('setClientPortalData', result.data)
        return result.data
      })
    },
    resetClientPortalData({ commit }) {
      commit('resetClientPortalData')
    },
    fetchClientPortalSelectedTour({ commit }, touruid) {
      var selectedTourData = this.state.clientPortalData.tours.filter((tour) => {
        return tour.uid === touruid
      })
      commit('setClientPortalSelectedTour', selectedTourData[0])
    },
    fetchMarketReportData({ commit }, marketreportdata) {
      return axios.post('marketreport-reportdata.php', marketreportdata).then((result) => {
        commit('setMarketReportData', result.data)
        return result.data
      })
    },
    fetchMarketReportDataPublic({ commit }, marketreportdata) {
      return axios.post('marketreport-reportdata.php', marketreportdata).then((result) => {
        if (result.data.Status == 'Active') {
          commit('setMarketReportData', result.data)
          return result.data
        } else {
          return false
        }
      })
    },
    resetMarketReportData({ commit }) {
      commit('resetMarketReportData')
    },
    marketReportOnlyFlip({ commit }) {
      commit('flipMarketReportOnly')
    },
    fetchStatsOrgs({ commit }, modeAndData) {
      return axios.post('stats-orgs.php', modeAndData).then((result) => {
        if (modeAndData.mode == 'single') {
          commit('setStatsOrg', result.data[0])
        } else if (modeAndData.mode == 'all') {
          commit('setStatsOrgs', result.data)
        }
      })
    },
    fetchStatsUsers({ commit }, modeAndData) {
      return axios.post('stats-users.php', modeAndData).then((result) => {
        if (modeAndData.mode == 'single') {
          commit('setStatsUser', result.data[0])
        } else if (modeAndData.mode == 'team') {
          commit('setStatsTeam', result.data)
        } else if (modeAndData.mode == 'all') {
          commit('setStatsUsers', result.data)
        }
      })
    },
    fetchStatsPlatform({ commit }) {
      return axios.post('stats-platform.php').then((result) => {
        commit('setStatsPlatform', result.data)
      })
    },
    fetchStatsSessions({ commit }) {
      var orgContext = {
        mode: 'single',
        orguid: this.state.userData.OrgUID
      }
      return axios.post('stats-sessions.php', orgContext).then((result) => {
        commit('setStatsSessions', result.data)
      })
    },
    filterProps({ commit }) {
      return axios
        .post('props-filtered-flat.php', this.getters.GetPropFilterParamsFriendly)
        .then((result) => {
          commit('setPropFilterResultsProps', result.data)
          return result
        })
    },
    filterPropsEntireMap({ commit }) {
      var paramsWithEntireMap = this.getters.GetPropFilterParamsFriendly
      paramsWithEntireMap.filterMapAreaOnly = false
      return axios.post('props-filtered-flat.php', paramsWithEntireMap).then((result) => {
        commit('setPropFilterResultsPropsEntireMap', result.data)
        return result
      })
    },
    filterMapAreaTrue({ commit }) {
      commit('setFilterMapAreaTrue')
    },
    updateMapBounds({ commit }, boundsData) {
      commit('setUpdateMapBounds', boundsData)
      return true
    },
    formModal({ commit }, contextData) {
      commit('formModalActiveTrue')
      commit('formModal', contextData)
    },
    formModalFalse({ commit }) {
      commit('formModalFalse')
      commit('formModalActiveFalse')
    },
    tokenSecureFetch({ commit }, markerData) {
      commit('tokenSecureActiveTrue')
      var parameters = {
        propuid: markerData.PropUID,
        orguid: this.state.userData.OrgUID
      }
      axios.post('tokenized-prop-secure.php', parameters).then((result) => {
        commit('tokenSecureFetch', result.data)
      })
    },
    tokenSecureFetchLocOnly({ commit }, locData) {
      commit('tokenSecureActiveTrue')
      var parameters = {
        locuid: locData.UID,
        orguid: this.state.userData.OrgUID
      }
      axios.post('tokenized-loc-secure.php', parameters).then((result) => {
        commit('tokenSecureFetch', result.data)
      })
    },
    tokenSecureFalse({ commit }) {
      commit('tokenSecureFalse')
      commit('tokenSecureActiveFalse')
    },
    tokenPublicFetch({ commit }, propData) {
      commit('tokenPublicActiveTrue')
      var parameters = {
        propuid: propData.propuid,
        orguid: '111'
      } // This is now working where the return from the secure token api with the orguid set to 111 returns only public data
      return axios.post('tokenized-prop-public.php', parameters).then((result) => {
        commit('tokenPublicFetch', result.data)
        return result.data
      })
    },
    tokenPublicFalse({ commit }) {
      commit('tokenPublicFalse')
      commit('tokenPublicActiveFalse')
    },
    tokenClientFetch({ commit }, propData) {
      commit('tokenClientActiveTrue')
      var parameters = {
        clientid: this.state.clientPortalData.id,
        propuid: propData.PropUID
      }
      axios.post('tokenized-prop-client.php', parameters).then((result) => {
        commit('tokenClientFetch', result.data)
      })
    },
    tokenClientFalse({ commit }) {
      commit('tokenClientFalse')
      commit('tokenClientActiveFalse')
    },
    clientPipelineFilterStatusFlip({ commit }, status) {
      commit('clientPipelineFilterStatusFlipSet', status)
    },
    clientPipelineFilterSearchChange({ commit }, string) {
      commit('clientPipelineFilterSearchChangeSet', string)
    },
    clientContextPipelineOnlyFlip({ commit }) {
      commit('clientContextPipelineOnlyFlipSet')
      return true
    },
    clientContextPipelineOnlyReset({ commit }) {
      commit('clientContextPipelineOnlyResetSet')
    },
    fetchLocToolProps({ commit }, locInfo) {
      return axios.post('loc-tool-props.php', locInfo).then((result) => {
        commit('setLocToolProps', result.data)
        return result.data
      })
    },
    changeMapOptionsCenter({ commit }, location) {
      commit('setMapOptionCenter', location)
    },
    imageLayoutImage({ commit }, info) {
      commit('setImageLayoutImage', info)
    },
    resetImageLayout({ commit }) {
      commit('setResetImageLayout')
    },
    updateImageChange({ commit }) {
      commit('setUpdateImageChange')
    }
  },
  mutations: {
    setEnvData(state, data) {
      state.envData = data
    },
    setUserData(state, data) {
      if (data) {
        state.userData = data
        state.userAuthenticated = true
        state.propFilterParams.MapNELat = Number(data.DefaultLat) + 0.001
        state.propFilterParams.MapNELng = Number(data.DefaultLng) + 0.001
        state.propFilterParams.MapSWLat = Number(data.DefaultLat) - 0.001
        state.propFilterParams.MapSWLng = Number(data.DefaultLng) - 0.001
        state.mapOptions.center.lng = Number(data.DefaultLng)
        state.mapOptions.center.lat = Number(data.DefaultLat)
      } else {
        state.userData = false
        state.userAuthenticated = false
      }
    },
    setUnknownUserData(state, data) {
      state.userData = data
      state.userAuthenticated = false
    },
    setOrgInfo(state, data) {
      state.orgInfo = data
    },
    setUsersByOrg(state, data) {
      state.orgUsers = data
    },
    setStatCardsByUser(state, data) {
      state.statCardsUser = data
    },
    setClientsByOrg(state, data) {
      state.orgClients = data
    },
    setClientInfo(state, data) {
      state.clientInfo = data
    },
    setClientUsers(state, data) {
      state.clientUsers = data
    },
    setClientPolygons(state, data) {
      state.clientPolygons = data
    },
    setClientLinks(state, data) {
      state.clientLinks = data
    },
    setClientTours(state, data) {
      state.clientTours = data
    },
    setClientProps(state, data) {
      state.clientProps = data
    },
    setClientSelectedTour(state, data) {
      state.clientSelectedTour = data
    },
    setClientSelectedTourPages(state, data) {
      state.clientSelectedTourPages = data
    },
    resetClientSelectedTour(state) {
      state.clientSelectedTour = false
    },
    resetClientSelectedTourPages(state) {
      state.clientSelectedTourPages = false
    },
    setMarketsByOrg(state, data) {
      state.orgMarkets = data
    },
    setAttributesByOrg(state, data) {
      state.orgAttributes = data
    },
    setCategoriesByOrg(state, data) {
      state.orgCategories = data
    },
    setMarketReportsByOrg(state, data) {
      state.orgMarketReports = data
    },
    setClientContextData(state, data) {
      state.clientContextData = data
    },
    resetClientContextData(state) {
      state.clientContextData = false
    },
    setClientPortalData(state, data) {
      state.clientPortalData = data
    },
    resetClientPortalData(state) {
      state.clientPortalData = false
    },
    setClientPortalSelectedTour(state, data) {
      state.clientPortalSelectedTour = data
    },
    setMarketReportData(state, data) {
      state.marketReportData = data
    },
    resetMarketReportData(state) {
      state.marketReportData = false
    },
    flipMarketReportOnly(state) {
      state.marketReportOnly = !state.marketReportOnly
    },
    setStatsUser(state, data) {
      state.statsUser = data
    },
    setStatsTeam(state, data) {
      state.statsTeam = data
    },
    setStatsUsers(state, data) {
      state.statsUsers = data
    },
    setStatsOrg(state, data) {
      state.statsOrg = data
    },
    setStatsOrgs(state, data) {
      state.statsOrgs = data
    },
    setStatsPlatform(state, data) {
      state.statsPlatform = data
    },
    setStatsSessions(state, data) {
      state.statsSessions = data
    },
    setPropFilterResultsProps(state, data) {
      state.propFilterResultsProps = data
    },
    setPropFilterResultsPropsEntireMap(state, data) {
      state.propFilterResultsProps = data
      state.propFilterParams.filterMapAreaOnly = false
    },
    setFilterMapAreaTrue(state) {
      state.propFilterParams.filterMapAreaOnly = true
    },
    setUpdateMapBounds(state, data) {
      state.propFilterParams.MapNELat = data.getNorthEast().lat()
      state.propFilterParams.MapNELng = data.getNorthEast().lng()
      state.propFilterParams.MapSWLat = data.getSouthWest().lat()
      state.propFilterParams.MapSWLng = data.getSouthWest().lng()
    },
    formModal(state, data) {
      state.formModal = data
    },
    formModalFalse(state) {
      state.formModal = false
    },
    formModalActiveFalse(state) {
      state.formModalActive = false
    },
    formModalActiveTrue(state) {
      state.formModalActive = true
    },
    tokenSecureFetch(state, data) {
      state.tokenSecure = data
    },
    tokenSecureFalse(state) {
      state.tokenSecure = false
    },
    tokenSecureActiveFalse(state) {
      state.tokenSecureActive = false
    },
    tokenSecureActiveTrue(state) {
      state.tokenSecureActive = true
    },
    tokenClientFetch(state, data) {
      state.tokenClient = data
    },
    tokenClientFalse(state) {
      state.tokenClient = false
    },
    tokenClientActiveFalse(state) {
      state.tokenClientActive = false
    },
    tokenClientActiveTrue(state) {
      state.tokenClientActive = true
    },
    tokenPublicFetch(state, data) {
      state.tokenPublic = data
    },
    tokenPublicFalse(state) {
      state.tokenPublic = false
    },
    tokenPublicActiveFalse(state) {
      state.tokenPublicActive = false
    },
    tokenPublicActiveTrue(state) {
      state.tokenPublicActive = true
    },
    clientPipelineFilterStatusFlipSet(state, status) {
      state.clientPipelineFilter.status[status] = !state.clientPipelineFilter.status[status]
    },
    clientPipelineFilterSearchChangeSet(state, string) {
      state.clientPipelineFilter.filterString = string
    },
    clientContextPipelineOnlyFlipSet(state) {
      if (!state.clientContextPipelineOnly) {
        state.propFilterParams.PropIDs = state.clientContextData.props.reduce(
          (accumulator, item) => (accumulator.push(item.PropID), accumulator),
          []
        )
        state.clientContextPipelineOnly = true
      } else {
        state.propFilterParams.PropIDs = []
        state.clientContextPipelineOnly = false
      }
    },
    clientContextPipelineOnlyResetSet(state) {
      state.propFilterParams.PropIDs = []
      state.clientContextPipelineOnly = false
    },
    setLocToolProps(state, data) {
      state.locToolProps = data
    },
    setMapOptionCenter(state, data) {
      state.mapOptions.center.lng = Number(data.Lng)
      state.mapOptions.center.lat = Number(data.Lat)
      state.mapOptions.zoom = 19
    },
    setImageLayoutImage(state, data) {
      if (data.slot === 'img-slot-1') {
        state.printViewImages['img-slot-1'] = data.image
      } else if (data.slot === 'img-slot-2') {
        state.printViewImages['img-slot-2'] = data.image
      } else if (data.slot === 'img-slot-3') {
        state.printViewImages['img-slot-3'] = data.image
      } else if (data.slot === 'img-slot-4') {
        state.printViewImages['img-slot-4'] = data.image
      } else if (data.slot === 'img-slot-5') {
        state.printViewImages['img-slot-5'] = data.image
      } else if (data.slot === 'img-slot-6') {
        state.printViewImages['img-slot-6'] = data.image
      }
    },
    setResetImageLayout(state) {
      state.printViewImages = {
        'img-slot-1': false,
        'img-slot-2': false,
        'img-slot-3': false,
        'img-slot-4': false,
        'img-slot-5': false,
        'img-slot-6': false
      }
    },
    setUpdateImageChange(state) {
      state.imageChange = Math.floor(Math.random() * 123456789)
    }
  }
})
