<template>
  <div class="overflow-hidden rounded-md bg-rsbrand-violet-md">
    <div
      class="bg-black bg-opacity-40 px-4 py-1.5 text-center font-light tracking-wide text-white text-opacity-30"
    >
      5 Person Team
    </div>
    <div class="flex h-full flex-col px-4 pb-5 pt-2">
      <div class="font text-lg tracking-wide text-white text-opacity-80">30 Day Free Trial, then</div>
      <div class="-mt-1 flex text-white">
        <div class="self-begin relative top-[10px] text-4xl font-semibold">$</div>
        <div class="mr-1.5 self-center text-8xl font-semibold tracking-tighter">36</div>
        <div class="relative -top-[6px] self-end font-semibold text-white text-opacity-50">/ Month</div>
      </div>
      <div class="mb-3 mt-1">
        <div class="mb-2 flex">
          <check-bold-icon class="mr-2 flex-none text-xl text-green-600" />
          <div class="text grow leading-tight text-white opacity-80">Private data repository</div>
        </div>
        <div class="mb-2 flex">
          <check-bold-icon class="mr-2 flex-none text-xl text-green-600" />
          <div class="text grow leading-tight text-white opacity-80">Cancel subscription at any time</div>
        </div>
        <div class="mb-2 flex">
          <check-bold-icon class="mr-2 flex-none text-xl text-green-600" />
          <div class="text grow leading-tight text-white opacity-80">Delete your data at any time</div>
        </div>
        <div class="mb-2 flex">
          <check-bold-icon class="mr-2 flex-none text-xl text-green-600" />
          <div class="text grow text-sm leading-tight text-white opacity-80">Up to 5 team members</div>
        </div>
      </div>
      <button
        class="focus:shadow-outline w-full rounded-md bg-rsbrand-violet-lt px-3 py-2 font-semibold text-white text-opacity-70 shadow-md hover:bg-white hover:text-rsbrand-violet-md hover:text-opacity-100"
        @click="redirectToStripe('team_s')"
        v-if="!this.isRedirecting"
      >
        Create An Account
      </button>
      <button
        class="focus:shadow-outline w-full rounded-md bg-rsbrand-violet-lt px-3 py-2 font-semibold text-white text-opacity-70 shadow-md"
        v-if="this.isRedirecting"
      >
        Redirecting to Stripe
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isRedirecting: false,
      gmailWhy: false
    }
  },
  methods: {
    toggleGmailWhy() {
      this.gmailWhy = !this.gmailWhy
    },
    redirectToStripe(subType) {
      this.isRedirecting = !this.isRedirecting
      this.$http.post('stripe-create-checkout-session.php', { type: subType }).then((result) => {
        console.log(result.data)
        window.location = result.data
      })
    }
  }
}
</script>
