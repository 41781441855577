export default {
  install: (app, options) => {
    app.config.globalProperties.$BuyerStatusColor = (event) => {
      if (event.status == 'Working' || event.status == 'In Lease' || event.status == 'Positive') {
        return 'rsstatus-green'
      } else if (event.status == 'Submission' || event.status == 'To Tour' || event.status == 'Hold') {
        return 'rsstatus-orange'
      } else if (event.status == 'Rejected' || event.status == 'Failed' || event.status == 'Negative') {
        return 'rsstatus-red'
      } else if (event.status == 'Existing' || event.status == 'Existing Location') {
        return 'rsstatus-blue'
      } else if (event.status == 'Disposition') {
        return 'rsstatus-teal'
      } else {
        return 'gray-400'
      }
    }
  }
}
