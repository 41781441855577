export default {
  install: (app, options) => {
    app.config.globalProperties.$DateToQuarterAndYear = (date) => {
      var year = date.substring(2, 4)
      var quarter = Number(date.substring(5, 7))
      if (quarter < 4) {
        quarter = 1
      } else if (quarter < 7) {
        quarter = 2
      } else if (quarter < 10) {
        quarter = 3
      } else if (quarter < 13) {
        quarter = 4
      }
      return 'Q' + quarter + '-' + year
    }
  }
}
