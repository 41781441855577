export default {
  install: (app, options) => {
    app.config.globalProperties.$FloorNumToName = (floor) => {
      if (floor == '-3') {
        return 'Utility Space'
      } else if (floor == '-2') {
        return 'Sub Basement'
      } else if (floor == '-1') {
        return 'Basement'
      } else if (floor == '1') {
        return 'Main Floor'
      } else if (floor == '2') {
        return 'Second Floor'
      } else if (floor == '3') {
        return 'Third Floor'
      } else if (floor == '4') {
        return 'Additional Space'
      } else {
        return 'Main Floor'
      }
    }
  }
}
